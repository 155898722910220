/* eslint-disable @typescript-eslint/no-unused-vars */
import create from 'zustand';

import { TODO } from './global.types';

export interface ImaginationIterationsStoreType {
  getImaginationIterations: (wsId: string, iId: string) => TODO;
}

const useImaginationsStore = create<ImaginationIterationsStoreType>(
  (set, get) => ({
    getImaginationIterations: (wsId: string, iId: string) => {
      const {} = get();
      return {};
    },
  })
);

export default useImaginationsStore;
