import { uuid } from '@supabase/supabase-js/dist/main/lib/helpers';
import {
  addDoc,
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  serverTimestamp,
  startAfter,
  updateDoc,
} from 'firebase/firestore';
import _ from 'lodash';
import create from 'zustand';

import API from 'utils/api';
import { db, updateRecents } from 'utils/api/firebase';
import { uploadFile } from 'utils/file';

import { TODO } from './global.types';
import { Voice } from './voices.types';

import { toast } from '@/components/ui/use-toast';

export interface VoicesStoreType {
  initialLoading: boolean;
  setInitialLoading: (initialLoading: boolean) => void;
  voices: Voice[];
  syncVoice: (voice: Voice) => void;

  globalVoices: Voice[];
  setGlobalVoices: (voices: Voice[]) => void;

  lastVisible: QueryDocumentSnapshot<DocumentData, DocumentData>;
  setLastVisible: (
    lastVisible: QueryDocumentSnapshot<DocumentData, DocumentData>
  ) => void;

  hasMore: boolean;
  setHasMore: (hasMore: boolean) => void;

  limitCount: number;

  getVoices: (wsId: string, uid: string, all?: boolean) => void;

  getGlobalVoices: () => void;

  getVoice: (wsId: string, voiceId: string, forceUpdate?: boolean) => TODO;

  createVoice: (wsId: string, uid: string, data: TODO) => void;

  updateVoice: (docRef: TODO, data: TODO, uid?: string) => void;
}

const useVoicesStore = create<VoicesStoreType>((set, get) => ({
  initialLoading: true,
  setInitialLoading: initialLoading => {
    set(() => ({ initialLoading }));
  },
  voices: [],
  syncVoice: voice => {
    set(({ voices }) => {
      const voiceIndex = voices.findIndex(c => c.id === voice.id);
      if (voiceIndex === -1) return { voices: [...voices, voice] };
      const updatedVoices = voices
        .filter(v => v.id !== voice.isArchive)
        .map((t, index) => (index === voiceIndex ? { ...t, ...voice } : t));

      return {
        voices: _.sortBy(updatedVoices, ['name']),
      };
    });
  },

  lastVisible: null,
  setLastVisible: lastVisible => {
    set(() => ({ lastVisible }));
  },

  hasMore: true,
  setHasMore: hasMore => {
    set(() => ({ hasMore }));
  },

  limitCount: 25,

  getVoices: async (wsId, uid, all = false) => {
    if (!wsId || !uid) return;
    const {
      limitCount,
      lastVisible,
      syncVoice: syncCollection,
      hasMore,
      setHasMore,
      setLastVisible,
      getVoices,
      setInitialLoading,
    } = get();

    if (!hasMore) return;

    // init q
    let q = query(
      collection(db, 'users', wsId, 'voices')
      // or(where('isArchive', 'in', [false, null]))
    );
    // add order and limit
    q = query(q, orderBy('name', 'asc'), limit(limitCount));
    // has no more starred
    if (lastVisible) {
      q = query(q, startAfter(lastVisible));
    }
    const documentSnapshots = await getDocs(q);
    setInitialLoading(false);
    documentSnapshots.forEach(doc => {
      syncCollection({ id: doc.id, ref: doc.ref, ...doc.data() });
    });
    if (documentSnapshots.empty) {
      setHasMore(false);
    }
    if (documentSnapshots.size) {
      setLastVisible(documentSnapshots.docs[documentSnapshots.size - 1]);
      if (all) getVoices(wsId, uid, all);
    }
  },

  globalVoices: [],
  setGlobalVoices: voices => {
    set(() => ({ globalVoices: voices }));
  },
  getGlobalVoices: async () => {
    const { globalVoices, setGlobalVoices } = get();
    if (globalVoices.length) return;
    const docRef = doc(db, 'others', 'elevenlabs.io');
    const docSnap = await getDoc(docRef);
    const { voices = [] } = docSnap.data();
    setGlobalVoices(_.sortBy(voices, ['name']));
  },

  getVoice: async (wsId, voiceId, forceUpdate) => {
    if (!wsId || !voiceId) return;
    const { voices, syncVoice } = get();
    const syncedVoice = voices.find(v => v.id === voiceId);
    if (syncedVoice && !forceUpdate) return syncedVoice;
    const docRef = doc(db, 'users', wsId, 'voices', voiceId);
    const docSnap = await getDoc(docRef);
    const voice = docSnap.exists()
      ? { id: docSnap.id, ref: docSnap.ref, ...docSnap.data() }
      : null;
    if (voice) syncVoice(voice);
    return voice;
  },

  createVoice: async (wsId, uid, data) => {
    try {
      const { getVoice } = get();
      if (!wsId) throw new Error('Workspace ID is required.');
      const { image, files, sampleFiles, ...restProps } = data;
      let imageUrl = '';
      if (image) {
        imageUrl = await uploadFile({ wsId, file: image });
      }
      const newSampleFiles = [];
      if (
        sampleFiles &&
        sampleFiles.length &&
        sampleFiles.some(file => file instanceof File)
      ) {
        for (const file of sampleFiles) {
          const fileUrl = await uploadFile({ wsId, file });
          newSampleFiles.push({
            id: uuid(),
            name: file.name,
            url: fileUrl,
          });
        }
      }

      const voiceDocRef = await addDoc(
        collection(db, 'users', wsId, 'voices'),
        {
          ...restProps,
          ...(imageUrl && { imageUrl }),
          ...(files && { files: [...files, ...newSampleFiles] }),
          createdBy: uid,
          createdAt: serverTimestamp(),
        }
      );

      console.log('voices -- createVoice -- voiceId:', voiceDocRef.id);

      getVoice(wsId, voiceDocRef.id, true);

      if (sampleFiles && sampleFiles.length) {
        API.addVoice({
          wsId,
          uid,
          vId: voiceDocRef.id,
        });
      }
    } catch (error) {
      if (process.env.IS_DEBUG)
        console.log('useVoicesStore -- createVoice -- error:', error);
      toast({
        title: 'Something went wrong. Please try again.',
      });
    }
  },

  updateVoice: async (docRef, data, uid) => {
    try {
      if (process.env.IS_DEBUG)
        console.log(
          'useVoicesStore -- updateVoice -- data:',
          data,
          'docRef:',
          docRef,
          'uid:',
          uid
        );

      const { getVoice } = get();
      const { image, sampleFiles, files, ...restProps } = data;

      const wsId = docRef.path.split('/')[1];
      let imageUrl = '';
      if (image) {
        imageUrl = await uploadFile({ wsId, file: image });
      }

      const newSampleFiles = [];
      if (
        sampleFiles &&
        sampleFiles.length &&
        sampleFiles.some(file => file instanceof File)
      ) {
        for (const file of sampleFiles) {
          const fileUrl = await uploadFile({ wsId, file });
          newSampleFiles.push({
            id: uuid(),
            name: file.name,
            url: fileUrl,
          });
        }
      }

      await updateDoc(docRef, {
        ...restProps,
        ...(imageUrl && { imageUrl }),
        ...(files && { files: [...files, ...newSampleFiles] }),
        updatedBy: uid,
        updatedAt: serverTimestamp(),
      });

      getVoice(wsId, docRef.id, true);

      if (sampleFiles && sampleFiles.length) {
        if (data?.voiceId) {
          if (process.env.IS_DEBUG) console.log('updateVoice -- editVoice');
          await API.editVoice({
            wsId,
            uid,
            vId: docRef.id,
          });
        } else {
          if (process.env.IS_DEBUG) console.log('updateVoice -- addVoice');
          await API.addVoice({
            wsId,
            uid,
            vId: docRef.id,
          });
        }
      }

      updateRecents({ uid, path: docRef.path });
    } catch (error) {
      console.log('useVoicesStore -- updateVoice -- error:', error);
      toast({
        title: 'Something went wrong. Please try again.',
      });
    }
  },

  getPersonaInstructions: async (wsId, voiceId, useContext) => {
    const { getVoice } = get();
    if (!voiceId) return [];
    if (!useContext) return [];
    const voice = await getVoice(wsId, voiceId);
    if (!voice) return [];
    const append = (label, value) => {
      return value ? `${label}: ${value}\n` : '';
    };
    return [
      {
        role: 'system',
        content: [
          append('Persona', voice.name),
          append('background', voice.background),
          append('Writing style', voice.writingStyle),
          append('Dos', voice.dos),
          append("Don'ts", voice.donts),
        ].join(''),
      },
    ];
  },
}));

export default useVoicesStore;
