import React, { ReactNode } from 'react';

import logoPng from 'assets/images/logo.png';

import * as Styled from './ErrorTemplate.styles';

interface ErrorTemplate {
  children: ReactNode;
}

const ErrorTemplate = ({ children }: ErrorTemplate) => {
  return (
    <Styled.Wrapper>
      <Styled.Header>
        <picture>
          <Styled.Logo
            src={logoPng}
            alt=""
            role="presentation"
            width="100"
            height="40"
            loading="lazy"
          />
        </picture>
      </Styled.Header>

      <Styled.Content>
        <Styled.Inner>{children}</Styled.Inner>
      </Styled.Content>
    </Styled.Wrapper>
  );
};

export default ErrorTemplate;
