import { z } from 'zod';

import { TODO } from './global.types';

export interface Thread {
  id: string;
  [key: string]: TODO;
}

export const threadSchema = z.object({
  id: z.string(),
  pinned: z.array(z.string()),
  readers: z.array(z.string()),
  editors: z.array(z.string()),
});

// export type Thread = z.infer<typeof threadSchema>;
