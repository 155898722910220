import * as Sentry from '@sentry/react';
import { useRouter } from 'next/router';
import { signOut } from 'next-auth/react';
import { useCallback } from 'react';

import { ROUTES } from 'utils/routes';

const IS_DEBUG = process.env.IS_DEBUG && true;

const useCommon = () => {
  const router = useRouter();
  // workspace id
  const wsId = router.query.wsId as string;
  // brand id
  const bId = router.query.bId as string;
  // thread id
  const tId = router.query.tId as string;
  // project id
  const pId = router.query.pId as string;
  // voiceover id
  const vId = router.query.vId as string;

  const onLogout = useCallback(async (route?: string) => {
    try {
      await signOut({
        callbackUrl: route || ROUTES.HOME,
      });
    } catch (error) {
      Sentry.captureException(error);
      if (IS_DEBUG) console.log('useCommon -- onLogout -- error:', error);
    }
  }, []);

  const routeTo = useCallback(
    async (route: string, uid?: string) => {
      router.push(route.replace('[wsId]', wsId || uid));
    },
    [router, wsId]
  );

  return {
    router,
    wsId,
    bId,
    tId,
    pId,
    vId,
    onLogout,
    routeTo,
  };
};

export default useCommon;
