import {
  createBrowserSupabaseClient,
  SupabaseClient,
} from '@supabase/auth-helpers-nextjs';
import axios, { AxiosInstance } from 'axios';
import getNextConfig from 'next/config';

const env = getNextConfig().publicRuntimeConfig;
export const baseURL = `${env.API_URL}/api`;

class API {
  client: AxiosInstance;
  supabase: SupabaseClient;

  constructor() {
    this.reset();
  }

  reset() {
    this.client = axios.create({ baseURL });
    this.client.defaults.withCredentials = false;
    this.client.defaults.headers.common = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    this.supabase = createBrowserSupabaseClient({
      supabaseUrl: env.SUPABASE_URL,
      // Do not use service key client-side
      supabaseKey: env.SUPABASE_ANON_KEY,
    });
  }

  async createCheckoutSession(uid: string, docId: string, priceId: string) {
    const response = await this.client.post(
      '/stripe/create-checkout-session',
      {
        uid,
        docId,
        priceId,
      },
      {
        headers: {
          Authorization: env.API_ROUTES_PUBLIC_KEY,
        },
      }
    );
    return response.data;
  }

  async addVoice(props: { wsId: string; uid: string; vId: string }) {
    const response = await this.client.post('/elevenLabs/add', props, {
      headers: {
        Authorization: env.API_ROUTES_PUBLIC_KEY,
      },
    });
    return response.data;
  }

  async editVoice(props: { wsId: string; uid: string; vId: string }) {
    const response = await this.client.post('/elevenLabs/edit', props, {
      headers: {
        Authorization: env.API_ROUTES_PUBLIC_KEY,
      },
    });
    return response.data;
  }

  async midJourneyGenerate(props: {
    wsId: string;
    uid: string;
    iId?: string;
    prompt: string;
  }) {
    const response = await this.client.post('/midJourney/generate', props, {
      headers: {
        Authorization: env.API_ROUTES_PUBLIC_KEY,
      },
    });
    return response.data;
  }

  async midJourneyVariation(props: {
    wsId: string;
    uid: string;
    iId: string;
    msgId;
    hash;
    flags;
    content;
    index;
  }) {
    const response = await this.client.post('/midJourney/variation', props, {
      headers: {
        Authorization: env.API_ROUTES_PUBLIC_KEY,
      },
    });
    return response.data;
  }

  async midJourneyZoomOut2x(props: {
    wsId: string;
    uid: string;
    iId: string;
    msgId: string;
    hash: string;
    flags: string;
  }) {
    const response = await this.client.post('/midJourney/zoomOut2x', props, {
      headers: {
        Authorization: env.API_ROUTES_PUBLIC_KEY,
      },
    });
    return response.data;
  }

  async imagineSd(props: {
    wsId: string;
    uid: string;
    iId?: string;
    prompt: string;
    negativePrompt?: string;
    width?: number;
    height?: number;
    presetStyle?: string;
    photoReal?: boolean;
  }) {
    const response = await this.client.post('/leonardo/generate', props, {
      headers: {
        Authorization: env.API_ROUTES_PUBLIC_KEY,
      },
    });
    return response.data;
  }

  async imagineReplicate(props: {
    wsId: string;
    uid: string;
    iId?: string;
    prompt: string;
    negativePrompt?: string;
    aspect: string;
    imageUrl?: string;
  }) {
    const response = await this.client.post('/replicate/generate', props, {
      headers: {
        Authorization: env.API_ROUTES_PUBLIC_KEY,
      },
    });
    return response.data;
  }

  async imagineIdeogram(props: {
    wsId: string;
    uid: string;
    iId?: string;
    prompt: string;
    negativePrompt?: string;
    aspect: string;
    style?: string;
    fileUrl?: string;
    fileWeight?: number;
    enhancePrompt?: boolean;
  }) {
    this.client.post('/ideogram/generate', props, {
      headers: {
        Authorization: env.API_ROUTES_PUBLIC_KEY,
      },
    });
  }

  async imagineVideo(props: {
    wsId: string;
    uid: string;
    iId?: string;
    prompt: string;
    enhancePrompt: boolean;
    isLoop?: boolean;
    imageUrl?: string;
    imageEndUrl?: string;
  }) {
    await this.client.post('/luma/generate', props, {
      headers: {
        Authorization: env.API_ROUTES_PUBLIC_KEY,
      },
    });
  }

  async extendVideo(props: {
    wsId: string;
    uid: string;
    iId?: string;
    prompt: string;
    enhancePrompt: boolean;
    isLoop?: boolean;
    id: string;
  }) {
    await this.client.post('/luma/extend', props, {
      headers: {
        Authorization: env.API_ROUTES_PUBLIC_KEY,
      },
    });
  }

  async imagineDallE(props: {
    wsId: string;
    uid: string;
    iId?: string;
    prompt: string;
    presetStyle?: string;
    size?: string;
  }) {
    const response = await this.client.post('/openai/generate', props, {
      headers: {
        Authorization: env.API_ROUTES_PUBLIC_KEY,
      },
    });
    return response.data;
  }

  async imagineAudio(props: {
    wsId: string;
    uid: string;
    iId?: string;
    prompt: string;
    duration?: number;
    model_version?: string;
  }) {
    const response = await this.client.post('/replicate/audio', props, {
      headers: {
        Authorization: env.API_ROUTES_PUBLIC_KEY,
      },
    });
    return response.data;
  }

  async describe(props: {
    wsId: string;
    uid: string;
    iId: string;
    iiId?: string;
    imageUrl: string;
    width?: number;
    height?: number;
  }) {
    const response = await this.client.post('/openai/describe', props, {
      headers: {
        Authorization: env.API_ROUTES_PUBLIC_KEY,
      },
    });
    return response.data;
  }

  async promptGeneration(props: {
    wsId: string;
    uid: string;
    iId: string;
    prompt: string;
  }) {
    const response = await this.client.post('/openai/promptGeneration', props, {
      headers: {
        Authorization: env.API_ROUTES_PUBLIC_KEY,
      },
    });
    return response.data;
  }

  async motion(props: {
    wsId: string;
    uid: string;
    iId: string;
    generationId: string;
    motionStrength?: number;
  }) {
    const response = await this.client.post('/leonardo/motion', props, {
      headers: {
        Authorization: env.API_ROUTES_PUBLIC_KEY,
      },
    });
    return response.data;
  }
  async motionV2(props: {
    wsId: string;
    uid: string;
    iId: string;
    imageUrl: string;
    motionStrength?: number;
  }) {
    const response = await this.client.post('/leonardo/motionV2', props, {
      headers: {
        Authorization: env.API_ROUTES_PUBLIC_KEY,
      },
    });
    return response.data;
  }

  async universalUpscaler(props: {
    wsId: string;
    uid: string;
    iId: string;
    imageUrl: string;
    width: number;
    height: number;
    prompt: string;
    upscalerStyle: string;
    creativityStrength: number;
    upscaleMultiplier: number;
  }) {
    const response = await this.client.post('/leonardo/upscale', props, {
      headers: {
        Authorization: env.API_ROUTES_PUBLIC_KEY,
      },
    });
    return response.data;
  }

  async generateVoiceoverVoice(props: { text: string; voiceId: string }) {
    const response = await this.client.post('/elevenLabs/tts', props, {
      headers: {
        Authorization: env.API_ROUTES_PUBLIC_KEY,
      },
    });
    return response.data;
  }

  async generateVoiceoverVoiceSts({ voiceId, blobUrl }) {
    const formData = new FormData();
    formData.append('voiceId', voiceId);
    formData.append('blobUrl', blobUrl);

    const response = await this.client.post('/elevenLabs/sts', formData, {
      headers: {
        Authorization: env.API_ROUTES_PUBLIC_KEY,
      },
    });

    return response.data;
  }

  async downloadArchive({
    files,
    archiveName,
  }: {
    files: { name: string; url: string; contentType: string }[];
    archiveName?: string;
  }) {
    const response = await this.client.post(
      '/downloadArchive',
      {
        files,
      },
      {
        responseType: 'blob',
      }
    );
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: 'application/zip' })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', archiveName || 'archive.zip');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    return response.data;
  }

  async downloadArchiveFile({
    files,
    archiveName,
  }: {
    files: { name: string; url: string; contentType: string }[];
    archiveName?: string;
  }) {
    const response = await this.client.post(
      '/downloadArchiveFile',
      {
        files,
        archiveName,
      },
      {
        responseType: 'blob',
      }
    );
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: 'application/zip' })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', archiveName || 'archive.zip');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    return response.data;
  }

  async downloadFile({
    file,
  }: {
    file: { name: string; url: string; contentType: string };
  }) {
    const response = await this.client.post(
      '/downloadFile',
      {
        file,
      },
      {
        responseType: 'blob',
      }
    );
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: file.contentType })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', file.name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    return response.data;
  }

  // Teams ---
  async resendInviteToWs(props: {
    to: string;
    toDisplayName: string;
    fromDisplayName: string;
  }) {
    const response = await this.client.post('/resend/inviteToWs', props, {
      headers: {
        Authorization: env.API_ROUTES_PUBLIC_KEY,
      },
    });
    return response.data;
  }

  // Chat ---
  async threadSave(props: {
    wsId: string;
    uid: string;
    id: string;
    messages: any[];
  }) {
    const response = await this.client.post('/thread/save', props, {
      headers: {
        Authorization: env.API_ROUTES_PUBLIC_KEY,
      },
    });
    return response.data;
  }

  // Suno ---
  async sunoGenerate(props: {
    wsId: string;
    uid: string;
    iId?: string;
    prompt: string;
    isInstrumental?: boolean;
  }) {
    const response = await this.client.post('/suno/generate', props, {
      headers: {
        Authorization: env.API_ROUTES_PUBLIC_KEY,
      },
    });
    return response.data;
  }
  async sunoGenerateCustom(props: {
    wsId: string;
    uid: string;
    iId?: string;
    prompt: string;
    title?: string;
    tags?: string;
    isInstrumental?: boolean;
  }) {
    const response = await this.client.post('/suno/generateCustom', props, {
      headers: {
        Authorization: env.API_ROUTES_PUBLIC_KEY,
      },
    });
    return response.data;
  }

  async generateOverview(props: { url: string }) {
    const response = await this.client.post('/openai/overview', props, {
      headers: {
        Authorization: env.API_ROUTES_PUBLIC_KEY,
      },
    });
    return response.data;
  }

  async generatePrompt(props: { prompt: string }) {
    const response = await this.client.post('/openai/prompt', props, {
      headers: {
        Authorization: env.API_ROUTES_PUBLIC_KEY,
      },
    });
    return response.data;
  }

  async generateEmbedding(props: { wsId: string; tId: string; mId: string }) {
    const response = await this.client.post('/thread/embedding', props, {
      headers: {
        Authorization: env.API_ROUTES_PUBLIC_KEY,
      },
    });
    return response.data;
  }

  async transcribeAudio(props: { wsId: string; tId: string; mId: string }) {
    const response = await this.client.post('/thread/transcribe', props, {
      headers: {
        Authorization: env.API_ROUTES_PUBLIC_KEY,
      },
    });
    return response.data;
  }
  async transcribeAudioIntent(props: {
    wsId: string;
    tId: string;
    uid: string;
    mId: string;
    iId: string;
  }) {
    const response = await this.client.post('/thread/transcribeIntent', props, {
      headers: {
        Authorization: env.API_ROUTES_PUBLIC_KEY,
      },
    });
    return response.data;
  }

  async generateThreadTitle(props: { wsId: string; tId: string }) {
    const response = await this.client.post('/thread/title', props, {
      headers: {
        Authorization: env.API_ROUTES_PUBLIC_KEY,
      },
    });
    return response.data;
  }

  async generateTaskThead(props: {
    wsId: string;
    uid: string;
    task: string;
    projects: string[];
    questions: {
      question: string;
      answer: string;
    }[];
    route: string;
  }) {
    const response = await this.client.post(
      `/thread/task/${props.route}`,
      props,
      {
        headers: {
          Authorization: env.API_ROUTES_PUBLIC_KEY,
        },
      }
    );
    return response.data;
  }

  async actions(props: { action: string; content: string }) {
    const response = await this.client.post(
      `/openai/actions/${props.action.toLowerCase()}`,
      {
        content: props.content,
      },
      {
        headers: {
          Authorization: env.API_ROUTES_PUBLIC_KEY,
        },
      }
    );
    return response.data;
  }

  async sendOTP(props: { to: string }) {
    const response = await this.client.post('/auth/otp', props, {
      headers: {
        Authorization: env.API_ROUTES_PUBLIC_KEY,
      },
    });
    return response.data;
  }

  async quickStart(props: {
    wsId: string;
    industry?: string;
    roles?: string[];
    level?: string;
    brandId?: string;
    projectId?: string;
  }) {
    const response = await this.client.post('/openai/quickstart', props, {
      headers: {
        Authorization: env.API_ROUTES_PUBLIC_KEY,
      },
    });
    return response.data;
  }
}

export default new API();
