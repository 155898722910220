import * as Icons from 'lucide-react';
import React, { ComponentPropsWithoutRef, ElementType } from 'react';

export const Icon = ({
  icon,
  ...props
}: { icon: string } & ComponentPropsWithoutRef<'svg'>) => {
  const Icon = Icons[icon as keyof typeof Icons] as ElementType;
  return Icon && <Icon {...props} />;
};
