/* eslint-disable react/no-unescaped-entities */
import { zodResolver } from '@hookform/resolvers/zod';
import { getDoc } from 'firebase/firestore';
import _ from 'lodash';
import { CheckIcon, ImagePlus, Pencil, Plus, Trash, X } from 'lucide-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import { useGlobalStore } from 'store';
import useBrandsStore from 'store/brands';
import useVoicesStore from 'store/voices';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { Textarea } from '@/components/ui/textarea';
import { cn } from '@/lib/utils';

const formSchema = z.object({
  image: z.union([z.instanceof(File), z.null()]).default(null),
  imageUrl: z.string(),
  name: z.string().min(1, { message: 'Name is required' }),
  brands: z.array(z.string()),
  nickname: z.string(),
  location: z.string(),
  pronoun: z.string(),
  description: z.string(),
  files: z
    .array(
      z
        .object({
          id: z.string().optional(),
          name: z.string(),
          url: z.string(),
          isArchive: z.boolean().optional().default(false),
        })
        .optional()
    )
    .optional()
    .default([]),
  sampleFiles: z.array(z.instanceof(File)).default([]),
  writingSample: z.string(),
  dos: z.string(),
  donts: z.string(),
  voiceId: z.string().optional(),
});

const formValueDefaults = {
  image: null,
  imageUrl: '',
  name: '',
  nickname: '',
  location: '',
  pronoun: '',
  isPersona: false,
  description: '',
  files: [],
  sampleFiles: [],
  writingSample: '',
  dos: '',
  donts: '',
  brands: [],
  voiceId: '',
};

const accept =
  'audio/aac, audio/x-aac, audio/aiff, audio/x-aiff, audio/ogg, audio/mpeg, audio/mp3, audio/mpeg3, audio/x-mpeg-3, audio/opus, audio/wav, audio/x-wav, audio/webm, audio/flac, audio/x-flac, audio/mp4, audio/x-m4a, video/mp4, video/x-msvideo, video/x-matroska, video/quicktime, video/x-ms-wmv, video/x-flv, video/webm, video/mpeg, video/3gpp';

const getFormValues = (data, defaults, omitKeys) => {
  return Object.keys(defaults).reduce((values, key) => {
    if (omitKeys.includes(key)) return values;
    values[key] = data && data[key] !== undefined ? data[key] : defaults[key];
    return values;
  }, {});
};

const CharacterFormView = ({ wsId, uid }) => {
  const filesInputRef = useRef(null);
  const imageInputRef = useRef(null);
  const [openItem, setOpenItem] = useState('overview');

  const {
    voiceBrandRef,
    setVoiceBrandRef,
    voiceRef,
    setVoiceRef,
    isCharacterOpen,
    setCharacterOpen,
  } = useGlobalStore();
  const { brands: brandsData } = useBrandsStore();
  const { createVoice, updateVoice } = useVoicesStore();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: formValueDefaults,
  });

  useEffect(() => {
    if (voiceRef) {
      getDoc(voiceRef).then(doc => {
        const data = doc.data();
        form.reset(getFormValues(data, formValueDefaults, []));
      });
    }
    if (voiceBrandRef) {
      const brand = brandsData.find(brand => brand.name === voiceBrandRef);
      if (brand)
        form.setValue(
          'brands',
          _.uniq([...form.getValues('brands'), brand.id])
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voiceRef, voiceBrandRef]);

  const onHandleClose = useCallback(() => {
    setCharacterOpen(false);
    setVoiceBrandRef(null);
    setVoiceRef(null);
    form.reset(formValueDefaults);
  }, [form, setCharacterOpen, setVoiceBrandRef, setVoiceRef]);

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    if (process.env.IS_DEBUG)
      console.log('characterFormView -- onSubmit -- values:', values);
    if (voiceRef) {
      updateVoice(voiceRef, values, uid);
    } else {
      createVoice(wsId, uid, values);
    }
    onHandleClose();
  };

  if (!isCharacterOpen) return null;
  return (
    <Dialog
      open={isCharacterOpen}
      onOpenChange={value => {
        if (value === false) onHandleClose();
      }}
    >
      <DialogContent className={'max-w-[987px] pr-0'}>
        <DialogHeader>
          <DialogTitle>{`${voiceRef ? 'Edit' : 'New'} Character`}</DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className={'pr-6'}>
            <div className={'grid grid-cols-[auto_1fr] gap-x-8'}>
              <FormField
                control={form.control}
                name="image"
                render={({ field }) => (
                  <FormItem className={'space-y-0 mb-0'}>
                    <FormLabel>Image</FormLabel>
                    <FormControl>
                      <div
                        className={
                          'w-28 h-28 rounded-full bg-muted text-muted-foreground grid group'
                        }
                      >
                        <ImagePlus
                          className="col-start-1 row-start-1 w-8 h-8 place-self-center text-muted-foreground"
                          strokeWidth={1.5}
                        />
                        {(field.value || form.getValues('imageUrl')) && (
                          <img
                            src={
                              field.value instanceof File
                                ? URL.createObjectURL(field.value)
                                : form.getValues('imageUrl')
                            }
                            alt="Character image"
                            className="col-start-1 row-start-1 w-full h-full object-cover rounded-full aspect-square"
                          />
                        )}
                        <div
                          className={
                            'w-28 h-28 col-start-1 row-start-1 cursor-pointer'
                          }
                          onClick={() => {
                            imageInputRef.current.click();
                          }}
                        />
                        {field.value && (
                          <div className="col-start-1 row-start-1 w-6 h-6 justify-self-center self-end grid bg-muted rounded-full mb-1 pointer-events-none">
                            <Pencil className="w-3 h-3 place-self-center" />
                          </div>
                        )}

                        <Input
                          type={'file'}
                          onChange={e => {
                            e.target.files[0] &&
                              field.onChange(e.target.files[0]);
                          }}
                          accept={'.jpg, .jpeg, .png'}
                          className={
                            'col-start-1 row-start-1 w-full h-full cursor-pointer hidden'
                          }
                          ref={imageInputRef}
                        />
                        {field.value && (
                          <div
                            className="col-start-1 row-start-1 w-6 h-6 justify-self-end grid bg-muted rounded-full invisible cursor-pointer group-hover:visible"
                            onClick={() => {
                              form.setValue('image', null);
                            }}
                          >
                            <X className="w-3 h-3 place-self-center" />
                          </div>
                        )}
                      </div>
                    </FormControl>
                    <FormDescription></FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className={'grid grid-cols-6 gap-x-3'}>
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem className={'col-span-3'}>
                      <FormLabel>Name</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormDescription></FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="nickname"
                  render={({ field }) => (
                    <FormItem className={'col-span-3'}>
                      <FormLabel>Nickname</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormDescription></FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {!!brandsData.length && (
                  <FormField
                    control={form.control}
                    name="brands"
                    render={({ field }) => (
                      <FormItem className={'col-span-2'}>
                        <FormLabel>Brands</FormLabel>
                        <FormControl>
                          <Popover>
                            <PopoverTrigger asChild>
                              <div
                                className={
                                  'w-full border px-3 py-1.5 rounded-md flex flex-wrap gap-1'
                                }
                              >
                                {field.value.length === 0 && (
                                  <span className={'text-gray-500 text-sm'}>
                                    Select brands
                                  </span>
                                )}
                                {field.value.map((value, index) => {
                                  const brand = brandsData.find(
                                    doc => doc.id === value
                                  );
                                  if (!brand) return null;
                                  const { name } = brand;
                                  return (
                                    <Badge
                                      key={index}
                                      variant={'default'}
                                      className={
                                        'rounded-full whitespace-nowrap pr-1.5'
                                      }
                                    >
                                      {name}
                                      <X
                                        className={
                                          'h-4 w-4 ml-0.5 cursor-pointer'
                                        }
                                        onClick={e => {
                                          e.stopPropagation();
                                          field.onChange(
                                            field.value.filter(
                                              (item: string) => item !== value
                                            )
                                          );
                                        }}
                                      />
                                    </Badge>
                                  );
                                })}
                              </div>
                            </PopoverTrigger>
                            <PopoverContent className="p-0" align={'start'}>
                              <Command>
                                <CommandInput
                                  placeholder="Search brand..."
                                  className="h-9"
                                />
                                <CommandList className="max-h-56 overflow-y-auto">
                                  <CommandEmpty>No brand found.</CommandEmpty>
                                  <CommandGroup>
                                    {_.sortBy(brandsData, ['name']).map(
                                      brand => {
                                        const id = brand.id;
                                        const { name } = brand;
                                        return (
                                          <CommandItem
                                            key={id}
                                            value={id}
                                            onSelect={() => {
                                              field.onChange(
                                                field.value.includes(id)
                                                  ? field.value.filter(
                                                      (item: string) =>
                                                        item !== id
                                                    )
                                                  : [...field.value, id]
                                              );
                                            }}
                                          >
                                            {name}
                                            {field.value.includes(id) && (
                                              <CheckIcon
                                                className={cn(
                                                  'ml-auto h-4 w-4'
                                                )}
                                              />
                                            )}
                                          </CommandItem>
                                        );
                                      }
                                    )}
                                  </CommandGroup>
                                </CommandList>
                              </Command>
                            </PopoverContent>
                          </Popover>
                        </FormControl>
                        <FormDescription></FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}

                <FormField
                  control={form.control}
                  name="location"
                  render={({ field }) => (
                    <FormItem className={'col-span-2'}>
                      <FormLabel>Location</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormDescription></FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="pronoun"
                  render={({ field }) => (
                    <FormItem className={'col-span-2'}>
                      <FormLabel>Pronoun</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormDescription></FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <Accordion
              type="single"
              className="w-full"
              value={openItem}
              onValueChange={setOpenItem}
              collapsible
            >
              <AccordionItem value="overview">
                <AccordionTrigger
                  className={'text-sm font-medium leading-none'}
                >
                  Overview
                </AccordionTrigger>
                <AccordionContent>
                  <FormField
                    control={form.control}
                    name={'description'}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Description</FormLabel>
                        <FormControl>
                          <Textarea maxRows={8} rows={4} {...field} />
                        </FormControl>
                        <FormDescription>
                          Tell us about your character: What's their personality
                          like? How do they behave? What do they like or
                          dislike? What makes them unique? Be Imaginative!
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="samples">
                <AccordionTrigger
                  className={'text-sm font-medium leading-none'}
                >
                  Samples
                </AccordionTrigger>
                <AccordionContent>
                  <FormField
                    control={form.control}
                    name="sampleFiles"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Voice sample files</FormLabel>
                        <FormControl>
                          <div>
                            {form
                              .watch('files')
                              .filter(file => file.isArchive !== true)
                              ?.map((file, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="flex justify-between items-center gap-2"
                                  >
                                    <span>{file.name}</span>

                                    {form.watch('sampleFiles').length === 0 &&
                                    form
                                      .watch('files')
                                      .filter(file => file.isArchive !== true)
                                      .length === 1 ? (
                                      <></>
                                    ) : (
                                      <Button
                                        variant="ghost"
                                        size="icon"
                                        className={'rounded-full'}
                                        type="button"
                                        onClick={() => {
                                          const files = form.getValues('files');
                                          form.setValue(
                                            'files',
                                            files.map((file, i) => {
                                              if (i === index) {
                                                return {
                                                  ...file,
                                                  isArchive: true,
                                                };
                                              }
                                              return file;
                                            })
                                          );
                                        }}
                                      >
                                        <Trash className={'h-4 w-4'} />
                                      </Button>
                                    )}
                                  </div>
                                );
                              })}
                            {form.watch('sampleFiles')?.map((file, index) => {
                              return (
                                <div
                                  key={index}
                                  className="flex justify-between gap-2"
                                >
                                  <span>{file.name}</span>
                                  {form
                                    .watch('files')
                                    .map(f => (f.isArchive ? null : f))
                                    .filter(Boolean).length === 0 &&
                                  form.watch('sampleFiles').length === 1 ? (
                                    <></>
                                  ) : (
                                    <Button
                                      variant="ghost"
                                      size="icon"
                                      className={'rounded-full'}
                                      type="button"
                                      onClick={() => {
                                        const sampleFiles =
                                          form.getValues('sampleFiles');
                                        form.setValue(
                                          'sampleFiles',
                                          sampleFiles.filter(
                                            (_, i) => i !== index
                                          )
                                        );
                                      }}
                                    >
                                      <Trash className={'h-4 w-4'} />
                                    </Button>
                                  )}
                                </div>
                              );
                            })}
                            <Input
                              ref={filesInputRef}
                              type={'file'}
                              onChange={e => {
                                field.onChange([
                                  ...form.getValues('sampleFiles'),
                                  e.target.files[0],
                                ]);
                                e.target.value = '';
                              }}
                              accept={accept}
                              className={'hidden'}
                            />
                            <Button
                              variant="outline"
                              type="button"
                              onClick={() => {
                                filesInputRef?.current?.click();
                              }}
                              size={'sm'}
                              className={'mt-2'}
                            >
                              <Plus className={'h-4 w-4 mr-2'} />
                              Add file
                            </Button>
                          </div>
                        </FormControl>
                        <FormDescription>
                          Upload audio files. Please ensure the audio is
                          recorded with a sample script in a quiet environment
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="writingSample"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Writing Style / Samples</FormLabel>
                        <FormControl>
                          <Textarea maxRows={8} rows={4} {...field} />
                        </FormControl>
                        <FormDescription>
                          {
                            "Add your writing examples such as emails, social media posts, or other written content. Remember: Don't include any sensitive or personal information. The more samples you provide, the help Euryka replicate your unique voice and writing style."
                          }
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="constraints">
                <AccordionTrigger
                  className={'text-sm font-medium leading-none'}
                >
                  Constraints
                </AccordionTrigger>
                <AccordionContent>
                  <FormField
                    control={form.control}
                    name="dos"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Do's</FormLabel>
                        <FormControl>
                          <Textarea maxRows={8} rows={4} {...field} />
                        </FormControl>
                        <FormDescription>
                          Define parameters we should always be cognisant of,
                          for example, way to write, specific nuances that
                          define your character.
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="donts"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Don'ts</FormLabel>
                        <FormControl>
                          <Textarea maxRows={8} rows={4} {...field} />
                        </FormControl>
                        <FormDescription>
                          Define paramters that the system should try and avoid
                          when generating content that you feel doesnt define
                          the character.
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </form>
        </Form>
        <DialogFooter className={'pr-6'}>
          <Button
            type="button"
            className={'float-end'}
            onClick={() => {
              form.handleSubmit(onSubmit)();
            }}
          >
            {voiceRef ? 'Update' : 'Create character'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
export default CharacterFormView;
