import { createGlobalStyle, css } from 'styled-components';

import { setDebug } from './mixins';
import { colors } from './theme';

const style = css`
  // * {
  //   ${setDebug()}
  // }

  body::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: hsl(var(--background));
  }
  body::-webkit-scrollbar-thumb {
    background-color: hsl(var(--foreground));
    border-radius: 6px;
    --webkit-font-smoothing: antialiased;
  }

  body::before {
    content: '';
    position: fixed;
    inset: 0;
    background: url('/images/gradient-noise-green-red.png') no-repeat center
      center;
    background-size: cover;
    filter: invert(1);
    opacity: 0.05;
  }
  body {
    overflow-x: hidden;
  }

  // photoswipe overrides
  .pswp__button--zoom,
  .pswp__button--close {
    display: none !important;
  }
  .pswp__custom--button {
    display: flex;
    align-items: center;
  }

  // img-comparison-slider overrides
  .img-comparison-slider {
    --default-handle-shadow: 0px 0px 5px ${colors.black}80;
    --divider-shadow: 0px 0px 5px ${colors.black}80;
  }
  .img-comparison-slider:focus {
    outline: none;
  }
`;

export default createGlobalStyle`
  ${style}
`;
