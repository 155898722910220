import { Undo2 } from 'lucide-react';
import { useTheme } from 'next-themes';
import React, { useCallback } from 'react';

import useCommon from 'hooks/useCommon';
import { isAccountPage, ROUTES } from 'utils/routes';

import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { useSvgIcon } from 'growl/hooks';

export interface SidebarDashboardProps {}

const defaultProps: Partial<SidebarDashboardProps> = {};

const SidebarDashboard: React.FC<SidebarDashboardProps> = () => {
  const { theme = 'system' } = useTheme();
  const { router, routeTo } = useCommon();

  const Logo = useSvgIcon(
    `logo-square-solid${theme === 'dark' ? '-invert' : ''}`
  );

  const onDashboardClick = useCallback(() => {
    routeTo(ROUTES.DASHBOARD_FALLBACK);
  }, [routeTo]);

  const onHomeClick = useCallback(() => {
    isAccountPage(router)
      ? router.push(ROUTES.WORKSPACE)
      : router.push(ROUTES.HOME);
  }, [router]);

  return (
    <div className={'flex flex-col items-center justify-center'}>
      <Logo.SvgIcon
        className={'h-12 w-12 my-6 cursor-pointer'}
        onClick={onHomeClick}
      />
      {isAccountPage(router) && (
        <Button
          variant="ghost"
          className={cn('rounded-full mb-2')}
          onClick={onDashboardClick}
        >
          <Undo2 className={'h-4 w-4'} />
        </Button>
      )}
    </div>
  );
};

SidebarDashboard.defaultProps = defaultProps;

export default SidebarDashboard;
