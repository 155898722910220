import styled from 'styled-components';

import { header, setTypography, setVh } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: ${setVh(100)};
  display: grid;
  grid-template-rows: max-content 1fr;
`;

export const Header = styled.div`
  ${header()}
`;

export const Logo = styled.img`
  width: 100rem;
  height: auto;
`;

export const Content = styled.div`
  display: grid;
  place-items: center;
  padding: 20rem;
`;

export const Inner = styled.div`
  display: grid;
  gap: 10rem;
  width: 100%;
  max-width: 430rem;
  padding: 50rem 40rem;
  background-color: ${colors.white};
  border-radius: 10rem;
  text-align: center;
  ${({ theme: { locale } }) => setTypography('body2', locale)}
`;
