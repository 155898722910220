import { KeyCode, KeyMod, useKeyboard } from '@geist-ui/core';
import { DollarSign, File, Sparkles, User } from 'lucide-react';
import React, { useCallback } from 'react';

import useCommon from 'hooks/useCommon';
import { useGlobalStore } from 'store';
import { ROUTES } from 'utils/routes';

import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/components/ui/command';

export interface LayoutCommandDialogProps {}

const defaultProps: Partial<LayoutCommandDialogProps> = {};

const LayoutCommandDialog: React.FC<LayoutCommandDialogProps> = () => {
  const { router, routeTo } = useCommon();

  const {
    isCommandOpen,
    setCommandOpen,
    setGenerateOpen,
    setGenerateOptions,
    setDescribeOpen,
    setDescribeOptions,
  } = useGlobalStore();

  useKeyboard(() => setCommandOpen(true), [KeyMod.CtrlCmd, KeyCode.KEY_E]);

  const onValueChange = useCallback(async (value: string) => {
    if (process.env.IS_DEBUG) console.log('onValueChange', value);
  }, []);

  const onSelect = useCallback(
    async (value: string) => {
      setCommandOpen(false);
      if (value === 'imagine') {
        const iId = router.query.iId as string;
        if (iId) setGenerateOptions({ iId });
        setGenerateOpen(true);
      }
      if (value === 'add a file') {
        const iId = router.query.iId as string;
        if (iId) setDescribeOptions({ iId });
        setDescribeOpen(true);
      }
      if (value === 'account') {
        routeTo(ROUTES.ACCOUNT_ORG);
      }
      if (value === 'subscription') {
        routeTo(ROUTES.ACCOUNT_BILLING);
      }
    },
    [
      routeTo,
      router.query.iId,
      setCommandOpen,
      setDescribeOpen,
      setDescribeOptions,
      setGenerateOpen,
      setGenerateOptions,
    ]
  );

  return (
    <CommandDialog open={isCommandOpen} onOpenChange={setCommandOpen}>
      <CommandInput
        placeholder="Type a command or search..."
        onValueChange={onValueChange}
      />
      <CommandList>
        <CommandEmpty>No results found.</CommandEmpty>
        <CommandGroup heading="Suggestions">
          <CommandItem onSelect={onSelect}>
            <Sparkles className="mr-2 h-4 w-4" />
            <span>Imagine</span>
          </CommandItem>
          <CommandItem onSelect={onSelect}>
            <File className="mr-2 h-4 w-4" />
            <span>Add a file</span>
          </CommandItem>
        </CommandGroup>
        <CommandSeparator />
        <CommandGroup heading="Settings">
          <CommandItem onSelect={() => onSelect('account')}>
            <User className="mr-2 h-4 w-4" />
            <span>Account</span>
          </CommandItem>
          <CommandItem onSelect={() => onSelect('subscription')}>
            <DollarSign className="mr-2 h-4 w-4" />
            <span>Subscription</span>
          </CommandItem>
        </CommandGroup>
      </CommandList>
    </CommandDialog>
  );
};

LayoutCommandDialog.defaultProps = defaultProps;

export default LayoutCommandDialog;
