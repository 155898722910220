import { zodResolver } from '@hookform/resolvers/zod';
import {
  AudioLines,
  BrainCircuit,
  Image,
  Infinity,
  Layers2,
  Loader2,
  RectangleHorizontal,
  RectangleVertical,
  Sailboat,
  Square,
  Video,
} from 'lucide-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import useImaginations from 'hooks/useImaginations';
import { useGlobalStore } from 'store';
import { pxToRems } from 'utils/styles/mixins';

import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Slider } from '@/components/ui/slider';
import { Switch } from '@/components/ui/switch';
import { Textarea } from '@/components/ui/textarea';
import { Tooltip, TooltipTrigger } from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';
import { useSvgIcon } from 'growl/hooks';

export interface LayoutGenerateDialogProps {}

const defaultProps: Partial<LayoutGenerateDialogProps> = {};

const formSchema = z
  .object({
    prompt: z.string().nonempty({
      message: 'Prompt is required',
    }),
    negativePrompt: z.string().optional(),
    model: z.string(),
    orientation: z.string(),
    isPhotoReal: z.boolean().optional(),
    style: z.string().optional(),
    iId: z.string().optional(),
    type: z.string().optional(),
    duration: z.number(),
    model_version: z.string().optional(),
    title: z.string().optional(),
    tags: z.string().optional(),
    isInstrumental: z.boolean().optional(),
    hasLyrics: z.boolean().optional(),
    isLoop: z.boolean().optional(),
    enhancePrompt: z.boolean().optional(),
    image: z.custom<File | undefined>(file => {
      if (file && !(file instanceof File)) {
        throw new Error('Image must be a file');
      }
      return true;
    }),
    imageWeight: z.number(),
    imageEnd: z.custom<File | undefined>(file => {
      if (file && !(file instanceof File)) {
        throw new Error('Image must be a file');
      }
      return true;
    }),
  })
  .refine(
    data => {
      if (data.hasLyrics) {
        return data.title?.length > 0;
      }
      return true;
    },
    {
      message: 'Title is required',
      path: ['title'],
    }
  )
  .refine(
    data => {
      if (data.hasLyrics) {
        return data.tags?.length > 0;
      }
      return true;
    },
    {
      message: 'Tags are required',
      path: ['tags'],
    }
  );

const modelVersions = [
  {
    key: 'stereo-melody-large',
    label: 'Echoes of Harmony',
    description:
      'Captures the essence of blending stereo sound and melody, suggesting a model that creates rich, harmonious echoes in music.',
  },
  {
    key: 'stereo-large',
    label: 'Sonic Panorama',
    description:
      "Emphasizes the model's ability to generate broad, immersive stereo soundscapes, akin to a rhapsodic exploration of audio panoramas.",
  },
  {
    key: 'melody-large',
    label: 'Lyrical Weaver',
    description:
      "Reflects the model's focus on melody, implying it weaves intricate, lyrical tapestries from musical notes.",
  },
  {
    key: 'large',
    label: 'Symphony Synthesist',
    description:
      "Conveys the model's extensive capacity to synthesize diverse musical elements into a cohesive symphony.",
  },
  {
    key: 'suno',
    label: 'Song smith',
    description: '',
  },
  // deprecated
  // {
  //   key: 'encode-decode',
  //   label: 'Aural Translator',
  //   description:
  //     "Highlights the model's role in translating and transforming different forms of inputs into coherent, audible outputs.",
  // },
];
const LayoutGenerateDialog: React.FC<LayoutGenerateDialogProps> = () => {
  const {
    isGenerateOpen,
    setGenerateOpen,
    generateOptions,
    setGenerateOptions,
  } = useGlobalStore();
  const OpenaiLogo = useSvgIcon('openai');

  const [isLoading, setLoading] = useState(false);
  const { onGenerate, onGenerateAudio, onGenerateVideo } = useImaginations();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      prompt: '',
      negativePrompt: '',
      model: 'midJourney',
      style: 'general',
      orientation: 'square',
      isPhotoReal: false,
      iId: '',
      type: 'image',
      duration: 8,
      model_version: 'stereo-large',
      title: '',
      tags: '',
      isInstrumental: true,
      hasLyrics: false,
      isLoop: false,
      enhancePrompt: true,
      image: undefined,
      imageWeight: 50,
    },
  });

  const formReset = useCallback(() => {
    form.reset();
  }, [form]);

  useEffect(() => {
    if (!generateOptions) return;
    form.setValue('prompt', generateOptions?.prompt || '');
    form.setValue('negativePrompt', generateOptions?.negativePrompt || '');
    form.setValue('model', generateOptions?.model || 'sd');
    form.setValue('style', generateOptions?.style || '');
    form.setValue('orientation', generateOptions?.orientation || 'square');
    form.setValue('isPhotoReal', generateOptions?.isPhotoReal || false);
    form.setValue('iId', generateOptions?.iId || '');
    form.setValue('type', generateOptions?.type || 'image');
    // meta/musicgen
    form.setValue('duration', generateOptions?.duration || 8);
    form.setValue(
      'model_version',
      generateOptions?.model_version || 'stereo-large'
    );
    // suno
    form.setValue('title', generateOptions?.title || '');
    form.setValue('tags', generateOptions?.tags || '');
    form.setValue('isInstrumental', generateOptions?.isInstrumental || true);
    form.setValue('hasLyrics', generateOptions?.isLyrics || false);
    form.setValue('isLoop', generateOptions?.isLoop || false);
    form.setValue('enhancePrompt', generateOptions?.enhancePrompt || true);
    form.setValue('image', undefined);
    form.setValue('imageWeight', 50);
    form.setValue('imageEnd', undefined);
  }, [form, generateOptions]);

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    if (process.env.IS_DEBUG)
      console.log('LayoutGenerateDialog -- onSubmit -- values:', values);
    setLoading(true);
    if (values.type === 'audio') {
      onGenerateAudio({
        prompt: values.prompt,
        duration: values.duration,
        model_version: values.model_version,
        iId: values.iId,
        title: values.title,
        tags: values.tags,
        isInstrumental: values.isInstrumental,
        hasLyrics: values.hasLyrics,
      });
    }
    if (values.type === 'image') {
      onGenerate({
        prompt: values.prompt,
        negativePrompt: values.negativePrompt,
        model: values.model,
        style: values.style,
        orientation: values.orientation,
        isPhotoReal: values.isPhotoReal,
        iId: values.iId,
        image: values.image,
        imageWeight: values.imageWeight,
        enhancePrompt: values.enhancePrompt,
      });
    }
    if (values.type === 'video') {
      onGenerateVideo({
        prompt: values.prompt,
        isLoop: values.isLoop,
        enhancePrompt: values.enhancePrompt,
        ...(values.image && { image: values.image }),
        ...(values.imageEnd && { imageEnd: values.imageEnd }),
        iId: values.iId,
      });
    }
    setGenerateOpen(false);
    setLoading(false);
    setGenerateOptions(null);
    formReset();
  };

  useEffect(() => {
    if (!isGenerateOpen) formReset();
  }, [formReset, isGenerateOpen]);

  return (
    <Dialog open={isGenerateOpen} onOpenChange={setGenerateOpen}>
      <DialogContent className={'max-w-3xl'}>
        <DialogHeader>
          <DialogTitle>Imagine</DialogTitle>
          {/* <DialogDescription></DialogDescription> */}
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="prompt"
              render={({ field }) => (
                <FormItem>
                  <div className="flex justify-between">
                    <FormLabel>
                      {form.watch('type') === 'audio' &&
                      form.watch('hasLyrics') === true
                        ? 'Lyrics'
                        : 'Prompt'}
                    </FormLabel>
                    <div
                      className={'flex flex-nowrap'}
                      style={{
                        gap: pxToRems(5),
                      }}
                    >
                      {[
                        {
                          key: 'image',
                          label: 'Image',
                          IconComponent: Image,
                        },
                        {
                          key: 'audio',
                          label: 'Audio',
                          IconComponent: AudioLines,
                        },
                        {
                          key: 'video',
                          label: 'Video',
                          IconComponent: Video,
                          disabled: false,
                        },
                      ].map(({ key, label, IconComponent, disabled }) => {
                        return (
                          <Tooltip key={key} delayDuration={100}>
                            <TooltipTrigger type={'button'}>
                              <Badge
                                variant={
                                  form.watch('type') === key
                                    ? 'default'
                                    : 'outline'
                                }
                                className={cn(
                                  'rounded-full font-normal cursor-pointer',
                                  disabled ? 'opacity-30' : ''
                                )}
                                onClick={() => {
                                  if (disabled) return;
                                  form.setValue('type', key);
                                }}
                              >
                                <IconComponent
                                  className={'h-3 w-3'}
                                  style={{
                                    marginRight:
                                      form.watch('type') === key
                                        ? '0.25rem'
                                        : 0,
                                  }}
                                />
                                {form.watch('type') === key && label}
                              </Badge>
                            </TooltipTrigger>
                          </Tooltip>
                        );
                      })}
                    </div>
                  </div>
                  <FormControl>
                    <Textarea
                      placeholder={
                        form.watch('type') === 'audio' &&
                        form.watch('hasLyrics') === true
                          ? 'Enter your own lyrics'
                          : 'Type a prompt'
                      }
                      rows={3}
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      maxRows={8}
                      {...field}
                    />
                  </FormControl>
                  <FormDescription></FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            {form.watch('type') === 'video' && (
              <>
                <div className={'flex gap-4'}>
                  <FormField
                    control={form.control}
                    name="isLoop"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Loop</FormLabel>
                        <FormControl>
                          <div>
                            <Switch
                              checked={field.value}
                              onCheckedChange={e => {
                                field.onChange(e);
                              }}
                            />
                          </div>
                        </FormControl>
                        <FormDescription></FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="enhancePrompt"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Enhance prompt</FormLabel>
                        <FormControl>
                          <div>
                            <Switch
                              checked={field.value}
                              onCheckedChange={e => {
                                field.onChange(e);
                              }}
                            />
                          </div>
                        </FormControl>
                        <FormDescription></FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <FormField
                  control={form.control}
                  name="image"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Start frame</FormLabel>
                      <FormControl>
                        <Input
                          type={'file'}
                          onChange={e => {
                            field.onChange(e.target.files[0]);
                          }}
                          accept={'.jpg, .jpeg, .png'}
                        />
                      </FormControl>
                      <FormDescription></FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {form.watch('image') && (
                  <FormField
                    control={form.control}
                    name="imageEnd"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>End frame</FormLabel>
                        <FormControl>
                          <Input
                            type={'file'}
                            onChange={e => {
                              field.onChange(e.target.files[0]);
                            }}
                            accept={'.jpg, .jpeg, .png'}
                          />
                        </FormControl>
                        <FormDescription></FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}
              </>
            )}
            {form.watch('type') === 'audio' && (
              <>
                <FormField
                  control={form.control}
                  name="model_version"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Model</FormLabel>
                      <FormControl>
                        <div className="flex items-center gap-1 flex-wrap">
                          {modelVersions.map(({ key, label }) => (
                            <Badge
                              key={key}
                              variant={
                                field.value === key ? 'default' : 'outline'
                              }
                              className="rounded-full font-normal cursor-pointer whitespace-nowrap"
                              onClick={() => {
                                field.onChange(key);
                              }}
                            >
                              {label}
                            </Badge>
                          ))}
                        </div>
                      </FormControl>
                      <FormDescription>
                        {
                          modelVersions.find(
                            modelVersion => modelVersion.key === field.value
                          ).description
                        }
                      </FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {form.watch('model_version') === 'suno' &&
                  form.watch('hasLyrics') && (
                    <>
                      <FormField
                        control={form.control}
                        name="tags"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Style of Music</FormLabel>
                            <FormControl>
                              <Input {...field} />
                            </FormControl>
                            <FormDescription></FormDescription>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="title"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Title</FormLabel>
                            <FormControl>
                              <Input {...field} />
                            </FormControl>
                            <FormDescription></FormDescription>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </>
                  )}
                {form.watch('model_version') === 'suno' && (
                  <div className={'flex gap-4'}>
                    <FormField
                      control={form.control}
                      name="isInstrumental"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Instrumental</FormLabel>
                          <FormControl>
                            <div>
                              <Switch
                                checked={field.value}
                                onCheckedChange={e => {
                                  field.onChange(e);
                                  if (e) form.setValue('hasLyrics', false);
                                }}
                              />
                            </div>
                          </FormControl>
                          <FormDescription></FormDescription>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="hasLyrics"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Custom lyrics</FormLabel>
                          <FormControl>
                            <div>
                              <Switch
                                checked={field.value}
                                onCheckedChange={e => {
                                  field.onChange(e);
                                  if (e) form.setValue('isInstrumental', false);
                                }}
                              />
                            </div>
                          </FormControl>
                          <FormDescription></FormDescription>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                )}
                {form.watch('model_version') !== 'suno' && (
                  <FormField
                    control={form.control}
                    name="duration"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          Duration - {form.watch('duration')} seconds
                        </FormLabel>
                        <FormControl>
                          <Slider
                            defaultValue={[form.watch('duration')]}
                            min={4}
                            max={60}
                            step={1}
                            onValueChange={([value]) => field.onChange(value)}
                          />
                        </FormControl>
                        <FormDescription></FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}
              </>
            )}
            {form.watch('type') === 'image' && (
              <>
                <FormField
                  control={form.control}
                  name="negativePrompt"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Negative prompt</FormLabel>
                      <FormControl>
                        <Input
                          placeholder={
                            "Type what you don't want to see in the image..."
                          }
                          {...field}
                        />
                      </FormControl>
                      <FormDescription></FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className={'flex gap-4'}>
                  <FormField
                    control={form.control}
                    name="model"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Model</FormLabel>
                        <FormControl>
                          <div className="flex items-center space-x-2">
                            {[
                              {
                                key: 'midJourney',
                                IconComponent: Sailboat,
                              },
                              {
                                key: 'sd',
                                IconComponent: Layers2,
                              },
                              {
                                key: 'flux',
                                IconComponent: Infinity,
                              },
                              {
                                key: 'dallE',
                                IconComponent: OpenaiLogo.SvgIcon,
                                scale: 1,
                              },
                              {
                                key: 'ideogram',
                                IconComponent: BrainCircuit,
                                scale: -1,
                              },
                            ].map(
                              ({
                                key,
                                // TODO: TBD model label
                                // label,
                                IconComponent,
                                scale,
                              }) => (
                                <Badge
                                  key={key}
                                  variant={
                                    field.value === key ? 'default' : 'outline'
                                  }
                                  className="rounded-full font-normal cursor-pointer"
                                  onClick={() => {
                                    field.onChange(key);
                                    if (key === 'midJourney')
                                      form.setValue('style', '');
                                    if (key === 'sd')
                                      form.setValue(
                                        'style',
                                        form.watch('isPhotoReal')
                                          ? 'cinematic'
                                          : 'dynamic'
                                      );
                                    if (key === 'dallE')
                                      form.setValue('style', 'vivid');
                                  }}
                                >
                                  <IconComponent
                                    className={'h-4 w-4'}
                                    style={{
                                      // TODO: TBD model label spacing
                                      // marginRight:
                                      // field.value === key ? '0.25rem' : 0,
                                      ...(scale && {
                                        transform: `scaleX(${scale})`,
                                      }),
                                    }}
                                  />
                                  {/* TODO: TBD model label */}
                                  {/* {field.value === key && label} */}
                                </Badge>
                              )
                            )}
                          </div>
                        </FormControl>
                        <FormDescription></FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="orientation"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Aspect ratio</FormLabel>
                        <FormControl>
                          <div
                            className={'flex flex-nowrap'}
                            style={{
                              gap: pxToRems(5),
                            }}
                          >
                            {[
                              {
                                key: 'square',
                                label: '1:1',
                                IconComponent: Square,
                              },
                              {
                                key: 'landscape',
                                label: '3:2',
                                IconComponent: RectangleHorizontal,
                              },
                              {
                                key: 'portrait',
                                label: '2:3',
                                IconComponent: RectangleVertical,
                              },
                            ].map(({ key, label, IconComponent }) => (
                              <Badge
                                key={key}
                                variant={
                                  field.value === key ? 'default' : 'outline'
                                }
                                className="rounded-full font-normal cursor-pointer"
                                onClick={() => field.onChange(key)}
                              >
                                <IconComponent
                                  className={'h-3 w-3'}
                                  style={{
                                    marginRight:
                                      field.value === key ? '0.25rem' : 0,
                                  }}
                                />
                                {field.value === key && label}
                              </Badge>
                            ))}
                          </div>
                        </FormControl>
                        <FormDescription></FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  {form.watch('model') === 'sd' && (
                    <FormField
                      control={form.control}
                      name="isPhotoReal"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Photo real</FormLabel>
                          <FormControl>
                            <div>
                              <Switch
                                checked={field.value}
                                onCheckedChange={e => {
                                  field.onChange(e);
                                  form.setValue(
                                    'style',
                                    e ? 'cinematic' : 'dynamic'
                                  );
                                }}
                              />
                            </div>
                          </FormControl>
                          <FormDescription></FormDescription>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  )}
                  {form.watch('model') === 'ideogram' && (
                    <FormField
                      control={form.control}
                      name="enhancePrompt"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Enhance prompt</FormLabel>
                          <FormControl>
                            <div>
                              <Switch
                                checked={field.value}
                                onCheckedChange={e => {
                                  field.onChange(e);
                                }}
                              />
                            </div>
                          </FormControl>
                          <FormDescription></FormDescription>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  )}
                </div>

                {(form.watch('model') === 'sd' ||
                  form.watch('model') === 'dallE' ||
                  form.watch('model') === 'ideogram') && (
                  <FormField
                    control={form.control}
                    name="style"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Style</FormLabel>
                        <FormControl>
                          <div
                            className={'flex flex-wrap'}
                            style={{
                              gap: pxToRems(5),
                            }}
                          >
                            {form.watch('model') === 'sd' &&
                              !form.watch('isPhotoReal') && (
                                <>
                                  <Badge
                                    variant={
                                      field.value === 'anime'
                                        ? 'default'
                                        : 'outline'
                                    }
                                    className={
                                      'rounded-full font-normal cursor-pointer'
                                    }
                                    onClick={() => field.onChange('anime')}
                                  >
                                    Anime
                                  </Badge>
                                </>
                              )}
                            {form.watch('model') === 'sd' &&
                              form.watch('isPhotoReal') && (
                                <>
                                  <Badge
                                    variant={
                                      field.value === 'bokeh'
                                        ? 'default'
                                        : 'outline'
                                    }
                                    className={
                                      'rounded-full font-normal cursor-pointer'
                                    }
                                    onClick={() => field.onChange('bokeh')}
                                  >
                                    Bokeh
                                  </Badge>
                                </>
                              )}

                            {form.watch('model') === 'sd' && (
                              <>
                                <Badge
                                  variant={
                                    field.value === 'cinematic'
                                      ? 'default'
                                      : 'outline'
                                  }
                                  className={
                                    'rounded-full font-normal cursor-pointer'
                                  }
                                  onClick={() => field.onChange('cinematic')}
                                >
                                  Cinematic
                                </Badge>
                              </>
                            )}

                            {form.watch('model') === 'sd' &&
                              form.watch('isPhotoReal') && (
                                <>
                                  <Badge
                                    variant={
                                      field.value === 'cinematic_closeup'
                                        ? 'default'
                                        : 'outline'
                                    }
                                    className={
                                      'rounded-full font-normal cursor-pointer'
                                    }
                                    onClick={() =>
                                      field.onChange('cinematic_closeup')
                                    }
                                  >
                                    Cinematic (Closeup)
                                  </Badge>
                                </>
                              )}

                            {form.watch('model') === 'sd' && (
                              <>
                                <Badge
                                  variant={
                                    field.value === 'creative'
                                      ? 'default'
                                      : 'outline'
                                  }
                                  className={
                                    'rounded-full font-normal cursor-pointer'
                                  }
                                  onClick={() => field.onChange('creative')}
                                >
                                  Creative
                                </Badge>
                              </>
                            )}

                            {form.watch('model') === 'sd' &&
                              !form.watch('isPhotoReal') && (
                                <>
                                  <Badge
                                    variant={
                                      field.value === 'dynamic'
                                        ? 'default'
                                        : 'outline'
                                    }
                                    className={
                                      'rounded-full font-normal cursor-pointer'
                                    }
                                    onClick={() => field.onChange('dynamic')}
                                  >
                                    Dynamic
                                  </Badge>
                                  <Badge
                                    variant={
                                      field.value === 'environment'
                                        ? 'default'
                                        : 'outline'
                                    }
                                    className={
                                      'rounded-full font-normal cursor-pointer'
                                    }
                                    onClick={() =>
                                      field.onChange('environment')
                                    }
                                  >
                                    Environment
                                  </Badge>
                                  <Badge
                                    variant={
                                      field.value === 'general'
                                        ? 'default'
                                        : 'outline'
                                    }
                                    className={
                                      'rounded-full font-normal cursor-pointer'
                                    }
                                    onClick={() => field.onChange('general')}
                                  >
                                    General
                                  </Badge>
                                  <Badge
                                    variant={
                                      field.value === 'illustration'
                                        ? 'default'
                                        : 'outline'
                                    }
                                    className={
                                      'rounded-full font-normal cursor-pointer'
                                    }
                                    onClick={() =>
                                      field.onChange('illustration')
                                    }
                                  >
                                    Illustration
                                  </Badge>
                                  <Badge
                                    variant={
                                      field.value === 'photography'
                                        ? 'default'
                                        : 'outline'
                                    }
                                    className={
                                      'rounded-full font-normal cursor-pointer'
                                    }
                                    onClick={() =>
                                      field.onChange('photography')
                                    }
                                  >
                                    Photography
                                  </Badge>
                                  <Badge
                                    variant={
                                      field.value === 'raytraced'
                                        ? 'default'
                                        : 'outline'
                                    }
                                    className={
                                      'rounded-full font-normal cursor-pointer'
                                    }
                                    onClick={() => field.onChange('raytraced')}
                                  >
                                    Raytraced
                                  </Badge>
                                  <Badge
                                    variant={
                                      field.value === 'render_3d'
                                        ? 'default'
                                        : 'outline'
                                    }
                                    className={
                                      'rounded-full font-normal cursor-pointer'
                                    }
                                    onClick={() => field.onChange('render_3d')}
                                  >
                                    Render 3D
                                  </Badge>
                                  <Badge
                                    variant={
                                      field.value === 'sketch_bw'
                                        ? 'default'
                                        : 'outline'
                                    }
                                    className={
                                      'rounded-full font-normal cursor-pointer'
                                    }
                                    onClick={() => field.onChange('sketch_bw')}
                                  >
                                    Sketch BW
                                  </Badge>
                                  <Badge
                                    variant={
                                      field.value === 'sketch_color'
                                        ? 'default'
                                        : 'outline'
                                    }
                                    className={
                                      'rounded-full font-normal cursor-pointer'
                                    }
                                    onClick={() =>
                                      field.onChange('sketch_color')
                                    }
                                  >
                                    Sketch Color
                                  </Badge>
                                </>
                              )}

                            {form.watch('model') === 'sd' &&
                              form.watch('isPhotoReal') && (
                                <>
                                  <Badge
                                    variant={
                                      field.value === 'fashion'
                                        ? 'default'
                                        : 'outline'
                                    }
                                    className={
                                      'rounded-full font-normal cursor-pointer'
                                    }
                                    onClick={() => field.onChange('fashion')}
                                  >
                                    Fashion
                                  </Badge>
                                  <Badge
                                    variant={
                                      field.value === 'film'
                                        ? 'default'
                                        : 'outline'
                                    }
                                    className={
                                      'rounded-full font-normal cursor-pointer'
                                    }
                                    onClick={() => field.onChange('film')}
                                  >
                                    Film
                                  </Badge>
                                  <Badge
                                    variant={
                                      field.value === 'food'
                                        ? 'default'
                                        : 'outline'
                                    }
                                    className={
                                      'rounded-full font-normal cursor-pointer'
                                    }
                                    onClick={() => field.onChange('food')}
                                  >
                                    Food
                                  </Badge>
                                  <Badge
                                    variant={
                                      field.value === 'hdr'
                                        ? 'default'
                                        : 'outline'
                                    }
                                    className={
                                      'rounded-full font-normal cursor-pointer'
                                    }
                                    onClick={() => field.onChange('hdr')}
                                  >
                                    HDR
                                  </Badge>
                                  <Badge
                                    variant={
                                      field.value === 'long_exposure'
                                        ? 'default'
                                        : 'outline'
                                    }
                                    className={
                                      'rounded-full font-normal cursor-pointer'
                                    }
                                    onClick={() =>
                                      field.onChange('long_exposure')
                                    }
                                  >
                                    Long exposure
                                  </Badge>
                                  <Badge
                                    variant={
                                      field.value === 'macro'
                                        ? 'default'
                                        : 'outline'
                                    }
                                    className={
                                      'rounded-full font-normal cursor-pointer'
                                    }
                                    onClick={() => field.onChange('macro')}
                                  >
                                    Macro
                                  </Badge>
                                  <Badge
                                    variant={
                                      field.value === 'minimalist'
                                        ? 'default'
                                        : 'outline'
                                    }
                                    className={
                                      'rounded-full font-normal cursor-pointer'
                                    }
                                    onClick={() => field.onChange('minimalist')}
                                  >
                                    Minimalist
                                  </Badge>
                                  <Badge
                                    variant={
                                      field.value === 'monochrome'
                                        ? 'default'
                                        : 'outline'
                                    }
                                    className={
                                      'rounded-full font-normal cursor-pointer'
                                    }
                                    onClick={() => field.onChange('monochrome')}
                                  >
                                    Monochrome
                                  </Badge>
                                  <Badge
                                    variant={
                                      field.value === 'moody'
                                        ? 'default'
                                        : 'outline'
                                    }
                                    className={
                                      'rounded-full font-normal cursor-pointer'
                                    }
                                    onClick={() => field.onChange('moody')}
                                  >
                                    Moody
                                  </Badge>
                                  <Badge
                                    variant={
                                      field.value === 'neutral'
                                        ? 'default'
                                        : 'outline'
                                    }
                                    className={
                                      'rounded-full font-normal cursor-pointer'
                                    }
                                    onClick={() => field.onChange('neutral')}
                                  >
                                    Neutral
                                  </Badge>
                                  <Badge
                                    variant={
                                      field.value === 'portrait'
                                        ? 'default'
                                        : 'outline'
                                    }
                                    className={
                                      'rounded-full font-normal cursor-pointer'
                                    }
                                    onClick={() => field.onChange('portrait')}
                                  >
                                    Portrait
                                  </Badge>
                                  <Badge
                                    variant={
                                      field.value === 'retro'
                                        ? 'default'
                                        : 'outline'
                                    }
                                    className={
                                      'rounded-full font-normal cursor-pointer'
                                    }
                                    onClick={() => field.onChange('retro')}
                                  >
                                    Retro
                                  </Badge>
                                  <Badge
                                    variant={
                                      field.value === 'stock_photo'
                                        ? 'default'
                                        : 'outline'
                                    }
                                    className={
                                      'rounded-full font-normal cursor-pointer'
                                    }
                                    onClick={() =>
                                      field.onChange('stock_photo')
                                    }
                                  >
                                    Stock Photo
                                  </Badge>
                                  <Badge
                                    variant={
                                      field.value === 'unprocessed'
                                        ? 'default'
                                        : 'outline'
                                    }
                                    className={
                                      'rounded-full font-normal cursor-pointer'
                                    }
                                    onClick={() =>
                                      field.onChange('unprocessed')
                                    }
                                  >
                                    Unprocessed
                                  </Badge>
                                </>
                              )}
                            {form.watch('model') === 'sd' && (
                              <>
                                <Badge
                                  variant={
                                    field.value === 'vibrant'
                                      ? 'default'
                                      : 'outline'
                                  }
                                  className={
                                    'rounded-full font-normal cursor-pointer'
                                  }
                                  onClick={() => field.onChange('vibrant')}
                                >
                                  Vibrant
                                </Badge>
                                <Badge
                                  variant={
                                    field.value === 'none'
                                      ? 'default'
                                      : 'outline'
                                  }
                                  className={
                                    'rounded-full font-normal cursor-pointer'
                                  }
                                  onClick={() => field.onChange('none')}
                                >
                                  None
                                </Badge>
                              </>
                            )}
                            {form.watch('model') === 'dallE' && (
                              <>
                                <Badge
                                  variant={
                                    field.value === 'vivid'
                                      ? 'default'
                                      : 'outline'
                                  }
                                  className={
                                    'rounded-full font-normal cursor-pointer'
                                  }
                                  onClick={() => field.onChange('vivid')}
                                >
                                  Vivid
                                </Badge>
                                <Badge
                                  variant={
                                    field.value === 'natural'
                                      ? 'default'
                                      : 'outline'
                                  }
                                  className={
                                    'rounded-full font-normal cursor-pointer'
                                  }
                                  onClick={() => field.onChange('natural')}
                                >
                                  Natural
                                </Badge>
                              </>
                            )}
                            {form.watch('model') === 'ideogram' && (
                              <>
                                <Badge
                                  variant={
                                    field.value === 'GENERAL'
                                      ? 'default'
                                      : 'outline'
                                  }
                                  className={
                                    'rounded-full font-normal cursor-pointer'
                                  }
                                  onClick={() => field.onChange('GENERAL')}
                                >
                                  General
                                </Badge>
                                <Badge
                                  variant={
                                    field.value === 'REALISTIC'
                                      ? 'default'
                                      : 'outline'
                                  }
                                  className={
                                    'rounded-full font-normal cursor-pointer'
                                  }
                                  onClick={() => field.onChange('REALISTIC')}
                                >
                                  Realistic
                                </Badge>
                                <Badge
                                  variant={
                                    field.value === 'DESIGN'
                                      ? 'default'
                                      : 'outline'
                                  }
                                  className={
                                    'rounded-full font-normal cursor-pointer'
                                  }
                                  onClick={() => field.onChange('DESIGN')}
                                >
                                  Design
                                </Badge>
                                <Badge
                                  variant={
                                    field.value === 'RENDER_3D'
                                      ? 'default'
                                      : 'outline'
                                  }
                                  className={
                                    'rounded-full font-normal cursor-pointer'
                                  }
                                  onClick={() => field.onChange('RENDER_3D')}
                                >
                                  Render 3D
                                </Badge>
                                <Badge
                                  variant={
                                    field.value === 'ANIME'
                                      ? 'default'
                                      : 'outline'
                                  }
                                  className={
                                    'rounded-full font-normal cursor-pointer'
                                  }
                                  onClick={() => field.onChange('ANIME')}
                                >
                                  Anime
                                </Badge>
                              </>
                            )}
                          </div>
                        </FormControl>
                        <FormDescription></FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}

                {(form.watch('model') === 'ideogram' ||
                  form.watch('model') === 'flux') && (
                  <>
                    <FormField
                      control={form.control}
                      name="image"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Image reference </FormLabel>
                          <FormControl>
                            <Input
                              type={'file'}
                              onChange={e => {
                                field.onChange(e.target.files[0]);
                              }}
                              accept={'.jpg, .jpeg, .png'}
                            />
                          </FormControl>
                          <FormDescription></FormDescription>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    {form.watch('image') && (
                      <FormField
                        control={form.control}
                        name="imageWeight"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Image weight</FormLabel>
                            <FormControl>
                              <Slider
                                defaultValue={[form.watch('imageWeight')]}
                                min={0}
                                max={100}
                                step={1}
                                onValueChange={([value]) =>
                                  field.onChange(value)
                                }
                              />
                            </FormControl>
                            <FormDescription></FormDescription>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    )}
                  </>
                )}
              </>
            )}

            <Button type="submit" disabled={isLoading} className={'float-end'}>
              {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
              Continue
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

LayoutGenerateDialog.defaultProps = defaultProps;

export default LayoutGenerateDialog;
