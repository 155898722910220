import { useEffect } from 'react';

import useUsersStore from 'store/users';

const useUser = (props: { uid: string }) => {
  const { uid } = props;
  const { users, getUser } = useUsersStore();

  useEffect(() => {
    if (uid) getUser(uid);
  }, [uid, getUser]);

  const user = users.find(u => u.id === uid || u.email === uid);
  const email = user?.email || '';

  return {
    user,
    name: user?.name || user?.displayName || '',
    email,
    photoURL: user?.imageURL || user?.photoURL || '',
    orgName: user?.organizationName || '',
    orgPhotoURL: user?.organizationImageURL || '',
    uidColor: user?.uidColor || '',
    defaultWorkspace: user?.defaultWorkspace || '',
    organizations: user?.organizations || {},
    betaFeatures: user?.betaFeatures || false,
    members: [
      ...(user ? [{ email, role: 'primaryOwner' }] : []),
      ...(user?.members || []),
    ],
    isOnboard: user?.isOnboard || false,
  };
};

export default useUser;
