import type { FirebaseOptions } from 'firebase/app';
import { getApp, getApps, initializeApp } from 'firebase/app';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  increment,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import getNextConfig from 'next/config';

const env = getNextConfig().publicRuntimeConfig;

const IS_DEBUG = process.env.IS_DEBUG && true;

export const projectId = env.FIREBASE_PROJECT_ID;
export const storageBucket = env.FIREBASE_STORAGE_BUCKET;

const firebaseConfig: FirebaseOptions = {
  apiKey: env.FIREBASE_API_KEY,
  authDomain: env.FIREBASE_AUTH_DOMAIN,
  databaseURL: env.FIREBASE_DATABASE_URL,
  projectId,
  storageBucket,
  messagingSenderId: env.FIREBASE_MESSAGING_SENDER_ID,
  appId: env.FIREBASE_APP_ID,
  measurementId: env.FIREBASE_MEASUREMENT_ID,
};

const firebaseAppName = 'Euryka';

export const firebaseApp = getApps().length
  ? getApp(firebaseAppName)
  : initializeApp(firebaseConfig, firebaseAppName);

export const firebaseAuth = getAuth(firebaseApp);

export const db = getFirestore(firebaseApp);

export const functions = getFunctions(firebaseApp, 'europe-west3');

export const storage = getStorage(firebaseApp);

// Bucket URL from Storage in Firebase Console
export const BUCKET_URL = env.BUCKET_URL;
export const BUCKET_URL_PREFIX = env.BUCKET_URL_PREFIX;

export const syncFirebaseAuth = async session => {
  const firebaseUser = firebaseAuth.currentUser;
  if (session?.firebaseToken) {
    if (!firebaseUser) {
      signInWithCustomToken(firebaseAuth, session.firebaseToken)
        .then(userCredential => {
          if (IS_DEBUG)
            console.log(
              'syncFirebaseAuth -- firebaseAuth.signInWithCustomToken -- success -- uid:  ',
              userCredential.user.uid
            );
        })
        .catch(error => {
          if (IS_DEBUG)
            console.error(
              'syncFirebaseAuth -- firebaseAuth.signInWithCustomToken -- error:',
              error
            );
        });
    } else if (firebaseUser.uid !== session.user.id) {
      firebaseAuth
        .signOut()
        .then(() => {
          return signInWithCustomToken(firebaseAuth, session.firebaseToken);
        })
        .then(userCredential => {
          if (IS_DEBUG)
            console.log(
              'syncFirebaseAuth -- firebaseAuth.signOut -- success -- uid: ',
              userCredential.user.uid
            );
        })
        .catch(error => {
          if (IS_DEBUG)
            console.error(
              'syncFirebaseAuth -- firebaseAuth.signOut -- error:',
              error
            );
        });
    } else {
      // User is already signed in to Firebase Authentication with the correct UID
      if (IS_DEBUG)
        console.log(
          'syncFirebaseAuth -- already signed in to Firebase with correct UID'
        );
    }
  } else if (firebaseUser) {
    firebaseAuth
      .signOut()
      .then(() => {
        if (IS_DEBUG)
          console.log('syncFirebaseAuth -- firebaseAuth.signOut -- success');
      })
      .catch(error => {
        if (IS_DEBUG)
          console.error(
            'syncFirebaseAuth -- firebaseAuth.signOut -- error:',
            error
          );
      });
  } else {
    if (IS_DEBUG) console.log('syncFirebaseAuth -- no session or firebaseUser');
  }
};

export const updateRecents = async (props: { uid: string; path: string }) => {
  try {
    const { uid, path } = props;
    const wsId = path.split('/')[1];
    const recentsCollectionRef = collection(
      db,
      `users/${wsId}/dashboard/${uid}/recents`
    );
    const q = query(recentsCollectionRef, where('path', '==', path));
    const docSnaps = await getDocs(q);
    if (!docSnaps.empty) {
      docSnaps.forEach(doc => {
        updateDoc(doc.ref, {
          updatedAt: serverTimestamp(),
        });
      });
      return;
    }
    await addDoc(recentsCollectionRef, {
      path: path,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
  } catch (error) {
    console.log('updateRecents -- error:', error);
  }
};

export const updateActivity = async (props: {
  wsId: string;
  uid: string;
  field: string;
}) => {
  const { wsId, uid, field } = props;
  const defaultDocPath = `users/${wsId}/activity/default`;
  const userDocPath = `users/${wsId}/activity/${uid}`;

  createOrUpdateActivity(defaultDocPath, field);
  createOrUpdateActivity(userDocPath, field);
};

const createOrUpdateActivity = async (docPath, field) => {
  const docRef = doc(db, docPath);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) {
    setDoc(docRef, {
      [`${field}`]: 1,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
    return;
  }

  updateDoc(docRef, {
    [field]: increment(1),
    updatedAt: serverTimestamp(),
  });
};
