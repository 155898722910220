import { formatDistance } from 'date-fns';
import { collection, orderBy, query, Timestamp } from 'firebase/firestore';
import { ArrowRight } from 'lucide-react';
import React, { useCallback, useEffect } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { toast } from 'sonner';

import { UserAvatar } from 'components/WsAvatar';
import useCommon from 'hooks/useCommon';
import useThread from 'hooks/useThread';
import useUser from 'hooks/useUser';
import { useGlobalStore } from 'store';
import { TODO } from 'store/global.types';
import useUsersStore from 'store/users';
import { db } from 'utils/api/firebase';
import { TOAST_DEFAULTS } from 'utils/config';
import { ROUTES } from 'utils/routes';

import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Separator } from '@/components/ui/separator';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet';

export interface Notifications {
  uid: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const IS_DEBUG = process.env.IS_DEBUG && true;

const defaultProps: Partial<Notifications> = {};

const Notifications: React.FC<Notifications> = ({ uid }) => {
  const { router } = useCommon();
  const { isNotificationsOpen, setNotificationsOpen } = useGlobalStore();
  const { notifications, getUser, syncNotification, updateNotification } =
    useUsersStore();

  // notifications
  const [value] = useCollection(
    query(
      collection(db, 'users', uid, 'notifications'),
      orderBy('createdAt', 'desc')
    ),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    }
  );

  // sync notifications
  const handleNotification = useCallback(
    async (notification: TODO) => {
      const { ref, isSeen, path, createdBy } = notification;

      if (!isSeen) {
        const user = await getUser(createdBy);
        const name = user?.name || user?.displayName;
        const segments = path.split('/');
        const wsId = segments[1];
        const threadId = segments[3];

        await updateNotification(ref, { isSeen: true });

        toast.info(
          `You have been added to a thread ${name ? `by ${name}` : ''}.`,
          {
            id: notification.id,
            ...TOAST_DEFAULTS,
            duration: 5000,
            action: {
              label: 'View',
              onClick: () => {
                router.push(
                  ROUTES.THREAD.replace('[wsId]', wsId).replace(
                    '[tId]',
                    threadId
                  )
                );
                updateNotification(ref, { isRead: true });
              },
            },
          }
        );
      }
    },
    [getUser, router, updateNotification]
  );

  useEffect(() => {
    if (value && value.docs.length > 0) {
      if (IS_DEBUG)
        console.log(
          'MainSync -- syncNotifications -- notifications:',
          value.docs.length
        );
      value.docs.forEach(doc => {
        const notification = { id: doc.id, ref: doc.ref, ...doc.data() };
        syncNotification(notification);
        handleNotification(notification);
      });
    }
  }, [handleNotification, value, syncNotification]);

  const notificationsGrouped = notifications
    .filter(notification => notification?.createdAt)
    .sort(
      (a, b) => b.createdAt.toDate().getTime() - a.createdAt.toDate().getTime()
    )
    .reduce((acc, item) => {
      const date = item.createdAt.toDate();
      let distance = formatDistance(date, new Date(), {
        addSuffix: true,
      });
      if (distance.includes('minute')) distance = 'Recents';
      if (!acc[distance]) acc[distance] = [];
      acc[distance].push(item);
      return acc;
    }, {});

  return (
    <Sheet open={isNotificationsOpen} onOpenChange={setNotificationsOpen}>
      <SheetContent
        className={'bg-muted grid grid-rows-[auto_1fr] pl-4 py-4 pr-0'}
        onOpenAutoFocus={e => {
          e.preventDefault();
        }}
      >
        <SheetHeader>
          <SheetTitle>Notifications</SheetTitle>
          {notifications.length === 0 && (
            <SheetDescription>No recent notifications</SheetDescription>
          )}
        </SheetHeader>
        <ScrollArea>
          <div className={'grid gap-4 pr-4'}>
            {Object.entries(notificationsGrouped).map(([distance, items]) => {
              return (
                <div key={distance} className={'space-y-2 mb-2'}>
                  <div className={'flex items-center gap-1 mb-2'}>
                    <span
                      className={
                        'text-xs  whitespace-nowrap text-muted-foreground'
                      }
                    >
                      {distance}
                    </span>
                    <Separator className={'shrink'} />
                  </div>
                  {Array.isArray(items) &&
                    items.map(item => {
                      if (isNotificationsOpen && !item.isRead)
                        updateNotification(item.ref, { isRead: true });
                      return (
                        <Notification key={item.id} {...{ uid, ...item }} />
                      );
                    })}
                </div>
              );
            })}
          </div>
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
};

Notifications.defaultProps = defaultProps;

export default Notifications;

const Notification = (props: {
  uid: string;
  path: string;
  createdBy: string;
  isSeen: boolean;
  isRead: boolean;
  createdAt: Timestamp;
}) => {
  const { router } = useCommon();
  const { setNotificationsOpen } = useGlobalStore();

  const { uid, path, createdBy } = props;

  const segments = path.split('/');
  const wsId = segments[1];
  const threadId = segments[3];

  const { email } = useUser({ uid });
  const { name } = useUser({ uid: createdBy });
  const { title, hasAccess } = useThread({ wsId, threadId, uid, email });

  return (
    <div
      className={
        'flex items-start gap-4 bg-card shadow-card p-4 pb-3.5 rounded-lg'
      }
    >
      <UserAvatar {...{ uid: createdBy, size: 'base' }} />
      <div>
        <p className="text-sm font-medium leading-1 line-clamp-1">{`${
          name ? `${name} added you` : 'You have been added'
        } to a thread.`}</p>
        <p className="text-sm text-muted-foreground line-clamp-1">
          Thread: &quot;{title}&quot;
        </p>
        <Button
          variant={'link'}
          size={'sm'}
          className={'p-0 h-[unset]'}
          onClick={() => {
            setNotificationsOpen(false);
            if (!hasAccess) {
              toast.error(
                'You are not allowed to access this thread',
                TOAST_DEFAULTS
              );
              return;
            }
            router.push(
              ROUTES.THREAD.replace('[wsId]', wsId).replace('[tId]', threadId)
            );
          }}
        >
          View thread
          <ArrowRight className={'ml-1 w-4 h-4'} />
        </Button>
      </div>
    </div>
  );
};
