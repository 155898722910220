import * as Sentry from '@sentry/react';
import { uuid } from '@supabase/supabase-js/dist/main/lib/helpers';
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import { ref, uploadBytes } from 'firebase/storage';
import { useSession } from 'next-auth/react';
import { useCallback } from 'react';
import { toast } from 'sonner';

import useGlobalStore from 'store/global';
import {
  imaginationIterationsCollection,
  imaginationsCollection,
} from 'store/imaginations';
import API from 'utils/api';
import { BUCKET_URL, BUCKET_URL_PREFIX, db, storage } from 'utils/api/firebase';
import { generateName, uploadFile } from 'utils/file';
import { ROUTES } from 'utils/routes';

import useCommon from './useCommon';
import useSubscriptions from './useSubscriptions';

const useImaginations = () => {
  const { router, routeTo, wsId } = useCommon();
  const { data } = useSession();
  const uid = data?.user?.id;
  const { setTrialOpen } = useGlobalStore();
  const { stripeRole, wasCustomer } = useSubscriptions({ wsId });

  const handleErrorToast = useCallback(
    (error: Error) => {
      Sentry.captureException(error);
      if (process.env.IS_DEBUG)
        console.log('useImaginations -- handleErrorToast -- error:', error);

      if (
        error.message &&
        error.message === 'Please check your billing status.'
      ) {
        if (wasCustomer) {
          toast('Something went wrong. Please try again.', {
            description: error.message,
            action: {
              label: 'Account',
              onClick: () => {
                routeTo(ROUTES.ACCOUNT_BILLING);
              },
            },
          });
          return;
        }
        setTrialOpen(true);
        return;
      }

      toast('Something went wrong. Please try again.', {
        ...(error.message && {
          description: error.message,
        }),
      });
    },
    [routeTo, setTrialOpen, wasCustomer]
  );

  const onGenerateAudio = useCallback(
    async (data: {
      prompt: string;
      duration: number;
      iId: string;
      model_version: string;
      title?: string;
      tags?: string;
      isInstrumental: boolean;
      hasLyrics: boolean;
    }) => {
      const { prompt, duration, model_version, iId, title, tags, hasLyrics } =
        data;
      try {
        if (stripeRole === 'free')
          throw new Error('Please check your billing status.');

        if (model_version === 'suno') {
          if (hasLyrics) {
            API.sunoGenerateCustom({
              wsId,
              uid,
              ...(iId && { iId }),
              prompt,
              title,
              tags,
              isInstrumental: data.isInstrumental,
            });
          } else {
            API.sunoGenerate({
              wsId,
              uid,
              ...(iId && { iId }),
              prompt,
              isInstrumental: data.isInstrumental,
            });
          }
        }
        if (model_version !== 'suno') {
          API.imagineAudio({
            wsId,
            uid,
            ...(iId && { iId }),
            prompt: prompt,
            duration,
            model_version,
          });
        }
      } catch (error) {
        handleErrorToast(error);
      }
    },
    [handleErrorToast, stripeRole, uid, wsId]
  );

  const onGenerateVideo = useCallback(
    async (props: {
      prompt: string;
      isLoop: boolean;
      enhancePrompt: boolean;
      iId?: string;
      image?: File;
      imageEnd?: File;
    }) => {
      try {
        if (stripeRole === 'free')
          throw new Error('Please check your billing status.');

        const { image, imageEnd, ...restProps } = props;
        let imageUrl = '';
        if (image) {
          imageUrl = await uploadFile({ wsId, file: image });
        }
        let imageEndUrl = '';
        if (imageEnd) {
          imageEndUrl = await uploadFile({ wsId, file: imageEnd });
        }

        API.imagineVideo({
          wsId,
          uid,
          ...restProps,
          ...(imageUrl && { imageUrl }),
          ...(imageEndUrl && { imageEndUrl }),
        });
      } catch (error) {
        handleErrorToast(error);
      }
    },
    [handleErrorToast, stripeRole, uid, wsId]
  );

  const onGenerate = useCallback(
    async (data: {
      prompt: string;
      negativePrompt: string;
      model: string;
      orientation: string;
      isPhotoReal: boolean;
      style: string;
      iId?: string;
      image?: File;
      imageWeight?: number;
      enhancePrompt?: boolean;
    }) => {
      try {
        if (stripeRole === 'free')
          throw new Error('Please check your billing status.');

        const {
          prompt,
          negativePrompt,
          model,
          orientation,
          isPhotoReal,
          style,
          iId,
          enhancePrompt = false,
        } = data;

        if (model === 'midJourney') {
          API.midJourneyGenerate({
            wsId,
            uid,
            ...(iId && { iId }),
            prompt: `${prompt}${
              orientation
                ? ` --aspect ${
                    orientation === 'portrait'
                      ? '2:3'
                      : orientation === 'landscape'
                      ? '3:2'
                      : '1:1'
                  }`
                : ''
            }${negativePrompt ? ` --no ${negativePrompt}` : ''}`,
          });
        }
        if (model === 'sd') {
          API.imagineSd({
            wsId,
            uid,
            ...(iId && { iId }),
            prompt: prompt,
            negativePrompt: negativePrompt,
            presetStyle: style,
            width:
              orientation === 'portrait'
                ? 512
                : orientation === 'landscape'
                ? 768
                : 512,
            height:
              orientation === 'portrait'
                ? 768
                : orientation === 'landscape'
                ? 512
                : 512,
            photoReal: isPhotoReal,
          });
        }
        if (model === 'flux') {
          let imageUrl = '';
          if (data.image) {
            imageUrl = await uploadFile({ wsId, file: data.image });
          }
          API.imagineReplicate({
            wsId,
            uid,
            ...(iId && { iId }),
            prompt: prompt,
            negativePrompt: negativePrompt,
            aspect:
              orientation === 'portrait'
                ? '2:3'
                : orientation === 'landscape'
                ? '3:2'
                : '1:1',
            ...(imageUrl && { imageUrl, fileWeight: data.imageWeight }),
          });
        }
        if (model === 'ideogram') {
          let imageUrl = '';
          if (data.image) {
            imageUrl = await uploadFile({ wsId, file: data.image });
          }
          API.imagineIdeogram({
            wsId,
            uid,
            ...(iId && { iId }),
            prompt: prompt,
            negativePrompt: negativePrompt,
            aspect:
              orientation === 'portrait'
                ? '2:3'
                : orientation === 'landscape'
                ? '3:2'
                : '1:1',
            style,
            ...(imageUrl && { imageUrl, fileWeight: data.imageWeight }),
            enhancePrompt,
          });
        }
        if (model === 'dallE') {
          API.imagineDallE({
            wsId,
            uid,
            ...(iId && { iId }),
            prompt: prompt,
            presetStyle: style,
            size:
              orientation === 'portrait'
                ? '1024x1792'
                : orientation === 'landscape'
                ? '1792x1024'
                : '1024x1024',
          });
        }

        if (iId)
          router.push(
            ROUTES.IMAGINATION.replace(
              '[wsId]',
              router.query.wsId as string
            ).replace('[iId]', iId)
          );
      } catch (error) {
        handleErrorToast(error);
      }
    },
    [handleErrorToast, router, stripeRole, uid, wsId]
  );

  const onDescribeUpload = useCallback(
    async (props: {
      iId?: string;
      title: string;
      file: File;
      describe?: boolean;
    }) => {
      if (stripeRole === 'free') {
        handleErrorToast(new Error('Please check your billing status.'));
        return;
      }

      const { iId, file, title, describe } = props;

      const getImageDimensions = file => {
        return new Promise<{ width: number; height: number }>(
          (resolve, reject) => {
            const img = new Image();
            // Create a URL for the file
            const url = URL.createObjectURL(file);
            // Load the image and resolve the promise with its dimensions
            img.onload = () => {
              // Revoke the object URL to free up memory
              URL.revokeObjectURL(url);
              resolve({ width: img.width, height: img.height });
            };
            img.onerror = () => {
              // Revoke the object URL in case of an error
              URL.revokeObjectURL(url);
              reject(new Error('Error loading image'));
            };
            // Set the image source to the file URL
            img.src = url;
          }
        );
      };

      // Await the image loading and log its dimensions
      const dimensions = await getImageDimensions(file);

      if (!dimensions.width || !dimensions.height) {
        toast('Something went wrong. Please try again.');
        return;
      }

      const originalFileName = file.name;
      const fileName = generateName(file, uuid());

      const imaginationDocRef = iId
        ? doc(db, `users/${wsId}/${imaginationsCollection}/${iId}`)
        : await addDoc(
            collection(db, `users/${wsId}/${imaginationsCollection}`),
            {
              title,
              type: 'describe',
              ...(!describe && {
                typeVariant: 'upload',
                typeVariantOption: 'image',
              }),
              loading: true,
              width: dimensions.width,
              height: dimensions.height,
              createdAt: serverTimestamp(),
              updatedAt: serverTimestamp(),
            }
          );

      updateDoc(imaginationDocRef, {
        loading: true,
        updatedAt: serverTimestamp(),
      });

      const iterationDocRef = await addDoc(
        collection(
          db,
          `users/${wsId}/${imaginationsCollection}/${imaginationDocRef.id}/${imaginationIterationsCollection}`
        ),
        {
          originalFileName,
          fileName,
          loading: true,
          title,
          type: 'describe',
          ...(!describe && {
            typeVariant: 'upload',
            typeVariantOption: 'image',
          }),
          width: dimensions.width,
          height: dimensions.height,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        }
      );

      try {
        const storageRef = ref(
          storage,
          `${BUCKET_URL}/users/${wsId}/images/${fileName}`
        );
        await uploadBytes(storageRef, file, {
          contentType: file.type,
          cacheControl: 'public, max-age=31536000, immutable',
        });

        const url = `${BUCKET_URL_PREFIX}users%2F${wsId}%2Fimages%2F${fileName}?alt=media`;

        updateDoc(imaginationDocRef, {
          uri: url,
          ...(!describe && {
            typeVariant: 'upload',
            typeVariantOption: 'image',
          }),
          loading: false,
          hasError: false,
          updatedAt: serverTimestamp(),
        });
        updateDoc(iterationDocRef, {
          url,
          loading: false,
          hasError: false,
          updatedAt: serverTimestamp(),
        });

        if (describe)
          API.describe({
            wsId,
            uid,
            iId: imaginationDocRef.id,
            iiId: iterationDocRef.id,
            imageUrl: url,
          });
      } catch (error) {
        Sentry.captureException(error);
        if (process.env.IS_DEBUG)
          console.log('useImaginations -- onDescribeUpload -- error:', error);

        updateDoc(imaginationDocRef, {
          loading: false,
          hasError: true,
          updatedAt: serverTimestamp(),
        });
        updateDoc(iterationDocRef, {
          loading: false,
          hasError: true,
          updatedAt: serverTimestamp(),
        });

        toast('Something went wrong. Please try again.');
      }
    },
    [handleErrorToast, stripeRole, uid, wsId]
  );

  const onGenerateBlend = useCallback(
    async (data: {
      prompt?: string;
      negativePrompt?: string;
      file?: File;
      anotherFile?: File;
      orientation: string;
    }) => {
      try {
        const { prompt, negativePrompt, file, anotherFile, orientation } = data;
        if (!file && !anotherFile) throw new Error('File is required');
        if (
          (file && !anotherFile && !prompt) ||
          (!file && anotherFile && !prompt)
        )
          throw new Error('Prompt is required');
        let fileUrl = '';
        let anotherFileUrl = '';
        if (file) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const originalFileName = file.name;
          const fileName = generateName(file, uuid());

          const storageRef = ref(
            storage,
            `${BUCKET_URL}/users/${wsId}/images/${fileName}`
          );
          await uploadBytes(storageRef, file, {
            contentType: file.type,
            cacheControl: 'public, max-age=31536000, immutable',
          });

          fileUrl = `${BUCKET_URL_PREFIX}users%2F${wsId}%2Fimages%2F${fileName}?alt=media `;
        }
        if (anotherFile) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const originalAnotherFileName = anotherFile.name;
          const anotherFileName = generateName(anotherFile, uuid());

          const storageRef = ref(
            storage,
            `${BUCKET_URL}/users/${wsId}/images/${anotherFileName}`
          );
          await uploadBytes(storageRef, anotherFile, {
            contentType: anotherFile.type,
            cacheControl: 'public, max-age=31536000, immutable',
          });

          anotherFileUrl = `${BUCKET_URL_PREFIX}users%2F${wsId}%2Fimages%2F${anotherFileName}?alt=media `;
        }

        const compiledPrompt = `${fileUrl}${anotherFileUrl}${prompt}`;
        API.midJourneyGenerate({
          wsId,
          uid,
          prompt: `${compiledPrompt}${
            orientation
              ? ` --aspect ${
                  orientation === 'portrait'
                    ? '2:3'
                    : orientation === 'landscape'
                    ? '3:2'
                    : '1:1'
                }`
              : ''
          }${negativePrompt ? ` --no ${negativePrompt}` : ''}`,
        });
      } catch (error) {
        handleErrorToast(error);
      }
    },
    [handleErrorToast, uid, wsId]
  );

  return {
    onGenerate,
    onGenerateAudio,
    onGenerateBlend,
    onDescribeUpload,
    onGenerateVideo,
  };
};

export default useImaginations;
