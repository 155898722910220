import styled from 'styled-components';

import { fullscreen } from 'utils/styles/mixins';
import { colors, layout } from 'utils/styles/theme';

export const Wrapper = styled.div`
  ${fullscreen()}
  background: ${colors.white};
  z-index: ${layout.zIndex.nonFunctionals};
  p {
    text-wrap: balance;
  }
`;
