import React from 'react';

import { useCopyStore } from 'store';
import { ReactComponent as SvgBrowserDesktop } from 'svgs/browser-desktop.svg';

import ErrorTemplate from '../ErrorTemplate/ErrorTemplate';

import * as NonFunctionalsStyles from '../NonFunctionals.styles';
import * as Styled from './DesktopOnly.styles';

const DesktopOnly = () => {
  const copies = useCopyStore(state => state.copy);

  if (!copies.global) return null;
  const {
    global: {
      errors: { desktopOnly: copy },
    },
  } = copies;

  return (
    <ErrorTemplate>
      <Styled.Icon>
        <SvgBrowserDesktop />
      </Styled.Icon>

      <NonFunctionalsStyles.Title>{copy.title}</NonFunctionalsStyles.Title>
      <NonFunctionalsStyles.Description
        dangerouslySetInnerHTML={{ __html: copy.body }}
      />
    </ErrorTemplate>
  );
};

export default DesktopOnly;
