import { zodResolver } from '@hookform/resolvers/zod';
import { Loader2 } from 'lucide-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import useImaginations from 'hooks/useImaginations';
import { useGlobalStore } from 'store';

import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';

export interface LayoutDescribeDialogProps {}

const defaultProps: Partial<LayoutDescribeDialogProps> = {};

const formSchema = z.object({
  title: z.string().nonempty({ message: 'Title is required' }),
  file: z.any().refine(file => file instanceof File, {
    message: 'Image is required.',
  }),
  describe: z.boolean(),
  iId: z.string().optional(),
});

const LayoutDescribeDialog: React.FC<LayoutDescribeDialogProps> = () => {
  const {
    isDescribeOpen,
    setDescribeOpen,
    describeOptions,
    setDescribeOptions,
  } = useGlobalStore();
  const { onDescribeUpload } = useImaginations();
  const [isLoading, setLoading] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: '',
      file: null,
      describe: true,
      iId: '',
    },
  });

  const formReset = useCallback(() => {
    form.reset();
  }, [form]);

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    setLoading(true);
    onDescribeUpload({
      iId: values.iId,
      title: values.title,
      file: values.file,
      describe: values.describe,
    });
    setDescribeOpen(false);
    setLoading(false);
    setDescribeOptions(null);
    formReset();
  };

  useEffect(() => {
    if (!describeOptions) return;
    form.setValue('iId', describeOptions.iId || '');
  }, [form, describeOptions]);

  useEffect(() => {
    if (!isDescribeOpen) formReset();
  }, [formReset, isDescribeOpen]);

  return (
    <Dialog open={isDescribeOpen} onOpenChange={setDescribeOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add a file</DialogTitle>
          {/* <DialogDescription></DialogDescription> */}
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Title</FormLabel>
                  <FormControl>
                    <Input placeholder={'Add a title...'} {...field} />
                  </FormControl>
                  <FormDescription></FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="file"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Image file</FormLabel>
                  <FormControl>
                    <Input
                      type={'file'}
                      onChange={e => {
                        field.onChange(e.target.files[0]);
                      }}
                      accept={'.jpg, .jpeg, .png'}
                    />
                  </FormControl>
                  <FormDescription></FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="describe"
              render={({ field }) => (
                <FormItem
                  className={'flex flex-row items-start space-x-3 space-y-0'}
                >
                  {/* <FormLabel></FormLabel> */}
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel>Describe this image.</FormLabel>
                    <FormDescription></FormDescription>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button type="submit" disabled={isLoading} className={'float-end'}>
              {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
              Continue
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

LayoutDescribeDialog.defaultProps = defaultProps;

export default LayoutDescribeDialog;
