import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  or,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import router from 'next/router';
import { toast } from 'sonner';
import create from 'zustand';

import { db } from 'utils/api/firebase';
import { superAdmins, TOAST_DEFAULTS, uidToHexColor } from 'utils/config';

import { TODO } from './global.types';
import { User } from './users.types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const IS_DEBUG = process.env.IS_DEBUG && true;

export interface UsersStoreType {
  users: User[];
  syncUser: (user: User) => void;
  getUser: (uid: string, forceUpdate?: boolean) => TODO;
  updateUser: (uid: string, data: TODO, routeTo?: string) => void;
  getWsRole: (
    wsId: string,
    uid: string
  ) => Promise<'primaryWsOwner' | 'owner' | 'editor' | 'reader'>;
  getWsPrivilege: (wsId: string, uid: string) => TODO;

  notifications: TODO;
  syncNotification: (notification: TODO) => void;
  addNotification: ({
    uid,
    createdBy,
    path,
  }: {
    uid: string;
    createdBy: string;
    path: string;
  }) => void;
  updateNotification: (notificationRef: TODO, data: TODO) => TODO;

  memberWorkspaces: TODO;
  setMemberWorkspaces: (memberWorkspaces: TODO) => void;
  updateMemberWorkspaces: (uemail: string, workspaces: TODO) => void;
}

const useUsersStore = create<UsersStoreType>((set, get) => ({
  users: [],
  syncUser: user => {
    set(({ users }) => {
      const userIndex = users.findIndex(t => t.id === user.id);
      if (userIndex === -1) return { users: [...users, user] };
      const updatedUsers = users.map((t, index) =>
        index === userIndex ? { ...t, ...user } : t
      );
      return {
        users: updatedUsers,
      };
    });
  },
  // uid - (uid / email)
  getUser: async (uid, forceUpdate = false) => {
    if (!uid) return;
    const { users, syncUser } = get();
    const user = users.find(u => u.id === uid || u.email === uid);
    if (user && !forceUpdate) return user;
    const q = query(
      collection(db, 'users'),
      or(where('user.email', '==', uid), where('user.uid', '==', uid))
    );
    const documentSnapshots = await getDocs(q);
    let userObj = {};
    documentSnapshots.forEach(doc => {
      const { user, organization, members = [] } = doc.data();
      userObj = {
        id: user.uid,
        defaultWorkspace: user?.defaultWorkspace || user.uid,
        email: user?.email || '',
        name: user?.name || '',
        displayName: user?.displayName || '',
        photoURL: user?.photoURL || '',
        imageURL: user?.imageURL || '',
        role: user?.role || '',
        industry: user?.industry || '',
        organizationName: organization?.name || '',
        organizationImageURL: organization?.imageURL || '',
        isOnboard: user?.isOnboard || false,
        members,
        uidColor: uidToHexColor(user.uid),
        organizations: user?.organizations || {},
        betaFeatures: user?.betaFeatures || false,
      };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      syncUser(userObj);
    });
    return userObj;
  },
  updateUser: async (uid, data, routeTo) => {
    const { getUser } = get();
    // updates user data in the database
    try {
      const docRef = doc(db, 'users', uid);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) throw new Error('User not found.');
      await updateDoc(docRef, {
        ...data,
        updatedAt: serverTimestamp(),
      });
      getUser(uid, true);
      if (routeTo)
        router.replace(routeTo).then(() => {
          router.reload();
        });
    } catch (error) {
      if (IS_DEBUG) console.log('useUsersStore -- updateUser -- error:', error);
      toast.error('Something went wrong. Please try again.', TOAST_DEFAULTS);
    }
  },
  getWsRole: async (wsId, uid) => {
    const { getUser } = get();
    if (wsId === uid) return 'primaryWsOwner';
    const user = await getUser(uid);
    const { role } = user.workspaces.find(w => w.id === wsId) || {
      role: 'reader',
    };
    return role;
  },
  getWsPrivilege: (wsId, uid) => {
    const privilege = {
      role: 'unknown',
      canView: false, // can read workspace
      canModify: false, // can edit workspace / team / subscriptions
      canEdit: false, // can edit team
    };
    if (!wsId || !uid) return privilege;
    const { users, getUser } = get();
    const user = users.find(u => u.id === uid);
    if (!user) {
      getUser(uid);
      return privilege;
    }
    const ws = users.find(w => w.id === wsId);
    if (!ws) {
      getUser(wsId);
      return privilege;
    }

    const role =
      ws?.members?.find(m => m.email === user.email)?.role || 'unknown';

    if (role === 'reader') {
      privilege.role = 'reader';
      privilege.canView = true;
      privilege.canModify = false;
      privilege.canEdit = false;
    }

    if (role === 'editor') {
      privilege.role = 'editor';
      privilege.canView = true;
      privilege.canModify = false;
      privilege.canEdit = true;
    }

    // super admins for quick access
    const isSuperAdmin = superAdmins.includes(uid);
    if (isSuperAdmin || role === 'owner' || wsId === uid) {
      privilege.role = isSuperAdmin
        ? 'superAdmin'
        : wsId === uid
        ? 'primaryOwner'
        : 'owner';
      privilege.canView = true;
      privilege.canModify = true;
      privilege.canEdit = true;
    }

    return privilege;
  },

  notifications: [],
  syncNotification: notification => {
    set(({ notifications }) => {
      const notificationIndex = notifications.findIndex(
        t => t.id === notification.id
      );
      if (notificationIndex === -1)
        return { notifications: [...notifications, notification] };
      const updatedNotifications = notifications.map((t, index) =>
        index === notificationIndex ? { ...t, ...notification } : t
      );
      return {
        notifications: updatedNotifications,
      };
    });
  },

  addNotification: async ({ uid, createdBy, path }) => {
    if (!uid || !createdBy || !path) return;
    const { getUser } = get();
    const user = await getUser(uid);
    if (!user) return;

    await addDoc(collection(db, 'users', user.id, 'notifications'), {
      path,
      isSeen: false,
      isRead: false,
      createdBy,
      createdAt: serverTimestamp(),
    });
  },

  updateNotification: async (notificationRef, data) => {
    if (!notificationRef) return;
    await updateDoc(notificationRef, { ...data });
  },

  memberWorkspaces: [],
  setMemberWorkspaces: workspaces => {
    set(() => ({
      memberWorkspaces: workspaces,
    }));
  },
  updateMemberWorkspaces: async (uemail, memberWorkspaces) => {
    const { setMemberWorkspaces } = get();
    const workspaces = memberWorkspaces.flatMap(mw => {
      const member = mw.members.find(m => m.email === uemail);
      const isValidStatus =
        member?.status === 'accepted' || member?.status === 'pending';

      return member?.role && isValidStatus
        ? [
            {
              id: mw.id,
              role: member.role,
              status: member.status,
            },
          ]
        : [];
    });

    setMemberWorkspaces(workspaces);
  },
}));

export default useUsersStore;
