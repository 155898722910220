import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';

export const Title = styled.h1`
  ${({ theme: { locale } }) => setTypography('heading2', locale)}
`;

export const Button = styled.button`
  ${({ theme: { locale } }) => setTypography('body1', locale)}
`;

export const Description = styled.p`
  white-space: pre-wrap;
  max-width: 3420rem;
`;
