import { User } from 'lucide-react';
import React, { useEffect } from 'react';

import useUser from 'hooks/useUser';
import useUsersStore from 'store/users';

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { AvatarStack } from '@/components/ui/avatar-stack';
import { cn } from '@/lib/utils';

export const WsAvatars = (props: {
  uids: string[];
  size?: keyof typeof sizeMap;
  sx?: string;
}) => {
  const { uids, size = 'base' } = props;
  const { users, getUser } = useUsersStore();
  useEffect(() => {
    uids.forEach(email => {
      getUser(email);
    });
  }, [getUser, uids]);
  return (
    <AvatarStack
      {...{
        avatars: uids.map(uid => {
          const user = users.find(u => u.id === uid || u.email === uid);
          const photoURL = user?.imageURL || user?.photoURL;
          const name = user?.name || user?.displayName || uid;
          return { name, photoURL };
        }),
        size,
      }}
      className={cn(props.sx)}
    />
  );
};

export const spaceMap = {
  '16': '-space-x-1 -space-y-1',
  '20': '-space-x-2 -space-y-2',
  '32': '-space-x-3 -space-y-3',
  base: '-space-x-2 -space-y-2',
  '48': '-space-x-4 -space-y-4',
  '144': '-space-x-12 -space-y-12',
};
export const fontSizeMap = {
  '16': 'text-xxxs',
  '20': 'text-xxs',
  '24': 'text-xs',
  '32': 'text-sm',
  '48': 'text-lg',
  '144': 'text-3xl',
};

const iconSizeMap = {
  '16': 'w-2 h-2',
  '20': 'w-3 h-3',
  '24': 'w-3 h-3',
  '32': 'w-4 h-4',
  base: 'w-5 h-5',
  '48': 'w-6 h-6',
  '144': 'w-10 h-10',
};
export const sizeMap = {
  '16': 'w-4 h-4',
  '20': 'w-5 h-5',
  '24': 'w-6 h-6',
  '32': 'w-8 h-8',
  base: 'w-10 h-10',
  '48': 'w-12 h-12',
  '144': 'w-36 h-36',
};

export const WsAvatar = (props: {
  photoURL?: string;
  name?: string;
  size?: keyof typeof sizeMap;
  sx?: string;
  fallback?: React.ReactNode;
}) => {
  const { photoURL, name, size = 'base', sx = '', fallback } = props;
  return (
    <Avatar className={cn('justify-center items-center', sizeMap[size], sx)}>
      <AvatarImage
        src={photoURL}
        alt={`@${name}`}
        className={cn('object-cover rounded-full')}
      />
      <AvatarFallback className={cn(fontSizeMap[size], 'select-none')}>
        {name ? (
          <>{userInitials(name)}</>
        ) : (
          <>
            {fallback ? (
              <>{fallback}</>
            ) : (
              <User className={cn(iconSizeMap[size])} />
            )}
          </>
        )}
      </AvatarFallback>
    </Avatar>
  );
};

export const userInitials = (name: string) =>
  name
    .split(' ')
    .map(word => word[0])
    .filter(char => /^[A-Za-z]$/.test(char))
    .filter(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (char, index, arr) => !/^(V[0-9]|VO)$/i.test(name.split(' ')[index])
    )
    .join('')
    .toUpperCase();

export const UserAvatar = (props: {
  uid: string;
  size: keyof typeof sizeMap;
  withName?: boolean;
}) => {
  const { uid, size, withName = false } = props;
  const { name, photoURL } = useUser({ uid });

  return withName ? (
    <div className={'flex items-center gap-2'}>
      <WsAvatar {...{ name, photoURL, size }} />
      <span className={'text-sm line-clamp-1 text-muted-foreground'}>
        {name}
      </span>
    </div>
  ) : (
    <WsAvatar {...{ name, photoURL, size }} />
  );
};
