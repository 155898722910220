/* eslint-disable @typescript-eslint/no-unused-vars */
import { kebabCase } from 'lodash';

import { Pages } from 'utils/routes';

import { CopyStoreType } from './copy.types';
import appEN from './translations/en/app.json';
import globalEN from './translations/en/global.json';
import headEN from './translations/en/head.json';

const TRANSLATIONS_PATH = './translations';

const tryRequire = (path: string) => {
  try {
    return require(`${path}`);
  } catch (error) {
    if (process.env.IS_DEBUG)
      console.log('copy.data -- tryRequire -- error:', error);

    return null;
  }
};

export const getCopy = (page: Pages, locale = process.env.DEFAULT_LOCALE) => {
  const path = `${TRANSLATIONS_PATH}/${kebabCase(locale)}`;
  // const head: Record<typeof page, CopyStoreType['copy']['head']> =
  //   tryRequire(`${path}/head.json`) ||
  //   (headEN as Record<
  //     typeof page,
  //     Pick<CopyStoreType['copy']['head'], 'title' | 'description' | 'ogImage'>
  //   >);

  return {
    head: {
      // ...head[page],
      title: 'Euryka - Your AI Swiss Army Knife',
      description:
        '/Imagine: Everything | The Intersection of AI and Productivity',
      ogImage: {
        url: 'https://app.euryka.ai/images/default_new_app_screenshot.jpg',
      },
      ogType: 'website',
    },
    global:
      tryRequire(`${path}/global.json`) ||
      (globalEN as CopyStoreType['copy']['global']),
    app:
      tryRequire(`${path}/app.json`) || (appEN as CopyStoreType['copy']['app']),
  } as CopyStoreType['copy'];
};
