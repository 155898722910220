/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import _ from 'lodash';
import { useSession } from 'next-auth/react';
import { useCallback } from 'react';
import { toast } from 'sonner';

import useUsersStore from 'store/users';
import API from 'utils/api';
import { db } from 'utils/api/firebase';
import { TOAST_DEFAULTS } from 'utils/config';

import useCommon from './useCommon';

const IS_DEBUG = process.env.IS_DEBUG && true;

const useAccount = () => {
  const { data } = useSession();
  const uid = data?.user?.id;

  const { getUser } = useUsersStore();

  const addMember = useCallback(
    async (props: { wsId: string; email: string; role: string }) => {
      const { wsId, email, role } = props;
      if (data?.user?.email === email) return;
      const wsDocRef = doc(db, 'users', wsId);
      const wsDocSnap = await getDoc(wsDocRef);
      if (!wsDocSnap.exists()) return;

      const { user, organization, memberIds } = wsDocSnap.data();
      if (memberIds.includes(email)) return;

      updateDoc(wsDocRef, {
        memberIds: arrayUnion(email),
        members: arrayUnion({
          email,
          status: 'pending',
          role: role,
        }),
        updatedAt: serverTimestamp(),
      });

      const wsName =
        organization?.name || user?.name || user?.displayName || '';

      API.resendInviteToWs({
        to: email,
        toDisplayName: '',
        fromDisplayName: wsName,
      });
    },
    [data?.user?.email]
  );

  const onAddMembers = useCallback(
    async (props: { wsId: string; emails: string[]; role: string }) => {
      try {
        const { wsId, emails, role } = props;
        for (const email of emails) {
          addMember({ wsId, email, role });
        }
      } catch (error) {
        if (IS_DEBUG)
          console.log('useAccount -- onAddMembers -- error:', error);
        toast.error('Something went wrong. Please try again.', TOAST_DEFAULTS);
      }
    },
    [addMember]
  );

  const onUpdateMember = useCallback(
    async (props: { wsId: string; email: string; role: string }) => {
      try {
        const { wsId, email, role } = props;
        const wsDocRef = doc(db, 'users', wsId);
        const wsDocSnap = await getDoc(wsDocRef);
        if (!wsDocSnap.exists()) return;

        const { members = [] } = wsDocSnap.data();
        const member = members.find(member => member.email === email);
        if (member) {
          await updateDoc(wsDocRef, {
            members: arrayRemove(member),
            updatedAt: serverTimestamp(),
          });
          await updateDoc(wsDocRef, {
            members: arrayUnion({ ...member, role }),
            updatedAt: serverTimestamp(),
          });
        }
      } catch (error) {
        if (IS_DEBUG)
          console.log('useAccount -- onUpdateMember -- error:', error);
        toast.error('Something went wrong. Please try again.', TOAST_DEFAULTS);
      }
    },
    []
  );

  const onDeleteMember = useCallback(
    async (props: { wsId: string; email: string }) => {
      try {
        const { wsId, email } = props;
        const wsDocRef = doc(db, 'users', wsId);
        const wsDocSnap = await getDoc(wsDocRef);
        if (!wsDocSnap.exists()) return;

        const { members = [] } = wsDocSnap.data();
        const member = members.find(member => member.email === email);
        if (member) {
          await updateDoc(wsDocRef, {
            members: arrayRemove(member),
            memberIds: arrayRemove(member.email),
            updatedAt: serverTimestamp(),
          });
        }
      } catch (error) {
        if (IS_DEBUG)
          console.log('useAccount -- onDeleteMember -- error:', error);
        toast.error('Something went wrong. Please try again.', TOAST_DEFAULTS);
      }
    },
    []
  );

  const onUpdateInvite = useCallback(
    async (
      uemail: string,
      inviteId: string,
      status: 'accepted' | 'declined'
    ) => {
      try {
        const { members } = await getUser(inviteId);
        const member = members.find(member => member.email === uemail);
        if (member) {
          await updateDoc(doc(db, 'users', inviteId), {
            members: arrayRemove(member),
            updatedAt: serverTimestamp(),
          });
          await updateDoc(doc(db, 'users', inviteId), {
            members: arrayUnion({ ...member, status }),
            updatedAt: serverTimestamp(),
          });
        }
      } catch (error) {
        if (IS_DEBUG)
          console.log('useAccount -- onUpdateMemberStatus -- error:', error);
        toast.error('Something went wrong. Please try again.', TOAST_DEFAULTS);
      }
    },
    [getUser]
  );

  return {
    onAddMembers,
    onUpdateMember,
    onDeleteMember,
    onUpdateInvite,
  };
};

export default useAccount;
