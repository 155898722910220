// import { addHours, format } from 'date-fns';
import Image from 'next/image';
import React from 'react';

import useCommon from 'hooks/useCommon';
import useSubscriptions from 'hooks/useSubscriptions';
import useGlobalStore from 'store/global';
import { ROUTES } from 'utils/routes';

import { AspectRatio } from '@/components/ui/aspect-ratio';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
// import {
//   Timeline,
//   TimelineContent,
//   TimelineDot,
//   TimelineHeading,
//   TimelineItem,
//   TimelineLine,
// } from '@/components/ui/timesline';

export interface LayoutTrialDialogProps {}

const defaultProps: Partial<LayoutTrialDialogProps> = {};

const LayoutTrialDialog: React.FC<LayoutTrialDialogProps> = () => {
  const { wsId, routeTo } = useCommon();

  const { isTrialOpen, setTrialOpen } = useGlobalStore();
  const { stripeRole, subscriptionsLoading } = useSubscriptions({ wsId });

  if (subscriptionsLoading || stripeRole !== 'free') return <></>;
  return (
    <Dialog open={isTrialOpen} onOpenChange={setTrialOpen}>
      <DialogContent className={'max-w-2xl'}>
        <DialogHeader>
          <DialogTitle
            className={'scroll-m-20 text-2xl font-semibold tracking-tight'}
          >
            Start your Euryka AI journey
          </DialogTitle>
        </DialogHeader>
        <AspectRatio ratio={680 / 160} className={''}>
          <Image
            src={'/images/ek-free-trial-image.webp'}
            alt="Start your free trial"
            fill
            className={'rounded-md object-cover'}
          />
        </AspectRatio>
        {/* <p className={'pb-2'}>
          Welcome! We are so glad you have joined Euryka. Let&apos;s get you
          started on your Al journey. The next few screens will get you up and
          running. First steps is to explore which plan you want to use.
        </p> */}
        <p className={'pb-2'}>
          Welcome! We are so glad you have joined Euryka. Let&apos;s get you
          started on your Al journey. First steps is to explore which plan you
          want to use.
        </p>
        {/* <h4 className={'scroll-m-20 text-xl font-semibold tracking-tight'}>
          What to expect
        </h4>
        <Timeline>
          <TimelineItem status="done">
            <TimelineHeading>Today</TimelineHeading>
            <TimelineDot status="done" />
            <TimelineLine done />
            <TimelineContent className={''}>
              Start your Euryka Al trial and see how it can transform they way
              you work.
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineHeading>Reminder</TimelineHeading>
            <TimelineDot />
            <TimelineLine />
            <TimelineContent className={''}>
              We will send you a reminder before your subscription starts.
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineHeading>
              {format(addHours(new Date(), 168), 'MMM d')}
            </TimelineHeading>
            <TimelineDot />
            <TimelineContent className={''}>
              Your subscription starts and you will be charged based on the
              selected plan.
            </TimelineContent>
          </TimelineItem>
        </Timeline> */}
        <DialogFooter className="sm:justify-start">
          <Button
            onClick={() => {
              routeTo(ROUTES.ACCOUNT_BILLING);
              setTrialOpen(false);
            }}
          >
            {/* Start your free trial */}
            Continue
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

LayoutTrialDialog.defaultProps = defaultProps;

export default LayoutTrialDialog;
