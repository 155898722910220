import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

import Loading from 'components/Loading/Loading';
import { Icon } from 'components/lucide';
import useCommon from 'hooks/useCommon';
import { fadeMotionProps } from 'utils/styles/animations';

import { Button } from '@/components/ui/button';

export interface LoadingScreenProps {
  isVisible?: boolean;
  refresh?: boolean;
}

const defaultProps: Partial<LoadingScreenProps> = {
  refresh: false,
};

const LoadingScreen: React.FC<LoadingScreenProps> = ({
  isVisible,
  refresh,
}) => {
  const { router } = useCommon();
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          {...fadeMotionProps}
          className="bg-background absolute top-0 left-0 flex flex-col gap-4 justify-center items-center h-full w-full"
        >
          <Loading className={'w-16 h-16'} />
          {refresh && (
            <Button
              variant={'link'}
              onClick={() => router.reload()}
              className={'animate-in fade-in delay-[5000ms] gap-1'}
            >
              Continue to Euryka
              <Icon icon={'ArrowRight'} className={'w-4 h-4'} />
            </Button>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

LoadingScreen.defaultProps = defaultProps;

export default LoadingScreen;
