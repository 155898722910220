import { useEffect, useMemo } from 'react';

import useThreadsStore from 'store/threads';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const IS_DEBUG = process.env.IS_DEBUG && true;

const useThread = (props: {
  wsId: string;
  threadId: string;
  uid?: string;
  email?: string;
}) => {
  const { wsId, threadId, uid, email } = props;
  const { threads, getThread } = useThreadsStore();

  const thread = useMemo(() => {
    return threads.find(thread => thread.id === threadId);
  }, [threadId, threads]);

  useEffect(() => {
    if (wsId && threadId) getThread(wsId, threadId);
  }, [getThread, wsId, threadId]);

  const createdBy = thread?.createdBy;
  const editors = thread?.editors || [];
  const readers = thread?.readers || [];

  const members = [...editors, ...readers];

  return {
    title: thread?.title || thread?.titleGenerated || '',
    editors,
    readers,
    hasAccess:
      (uid && members.includes(uid)) ||
      (email && members.includes(email)) ||
      (createdBy && uid && createdBy === uid),
  };
};

export default useThread;
