import { useSession } from 'next-auth/react';
import React from 'react';

import CharacterFormView from 'containers/CharactersPage/CharacterFormView';
import CollectionFormView from 'containers/CollectionsPage/CollectionFormView';
import VoiceoverFormView from 'containers/VoiceoversPage/VoiceoverFormView';
import useCommon from 'hooks/useCommon';
import { useGlobalStore } from 'store';

const SharedForms = () => {
  const { wsId } = useCommon();
  const { data: session } = useSession();
  const uid = session?.user?.id;

  const {
    isVoiceoverOpen,
    setVoiceoverOpen,
    voiceoverRef,
    setVoiceoverRef,
    isCollectionOpen,
    setCollectionOpen,
    collectionRef,
    setCollectionRef,
  } = useGlobalStore();

  return (
    <>
      {isVoiceoverOpen && uid && (
        <VoiceoverFormView
          wsId={wsId}
          uid={uid}
          open={isVoiceoverOpen}
          onOpenChange={() => {
            setVoiceoverOpen(false);
            setVoiceoverRef(null);
          }}
          voiceoverRef={voiceoverRef}
        />
      )}
      {wsId && uid && <CharacterFormView {...{ wsId, uid }} />}
      {isCollectionOpen && uid && (
        <CollectionFormView
          open={isCollectionOpen}
          onOpenChange={() => {
            setCollectionOpen(false);
            setCollectionRef(null);
          }}
          collectionRef={collectionRef}
        />
      )}
    </>
  );
};

export default SharedForms;
