import * as Sentry from '@sentry/react';
import { AnimatePresence } from 'framer-motion';
import { GetStaticProps } from 'next';
import { Router } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';

import { useCopyStore, useGlobalStore } from 'store';
import { getCopy } from 'store/copy.data';
import { CopyStoreType } from 'store/copy.types';
import { ISR_TIMEOUT } from 'utils/config';
import { Pages, ROUTES } from 'utils/routes';
import { desktopMinHeight, desktopMinWidth } from 'utils/styles/vars';

import DesktopOnly from './DesktopOnly/DesktopOnly';
import JavaScriptDisabled from './JavaScriptDisabled/JavaScriptDisabled';
import NotFound from './NotFound/NotFound';
import UnsupportedBrowser from './UnsupportedBrowser/UnsupportedBrowser';
import UnsupportedBanner from './UnsupportedBrowserBanner/UnsupportedBrowserBanner';
import WindowTooSmall from './WindowTooSmall/WindowTooSmall';

import useWindowSize from 'growl/hooks/useWindowSize';
import {
  ALLOWED_BROWSERS,
  isDesktop,
  isStorybook,
  isSupportedBrowser,
} from 'growl/utils/platform';

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  try {
    const selectedLocale = process.env.LOCALES.includes(locale)
      ? locale
      : process.env.DEFAULT_LOCALE;

    const initialCopy = getCopy(Pages.notFound, selectedLocale);

    return {
      props: {
        initialCopy,
      },
      revalidate: ISR_TIMEOUT,
    };
  } catch (error) {
    Sentry.captureException(error);
    console.log('NonFunctionals -- getStaticProps -- error:', error);

    throw new Error(error);
  }
};

interface NonFunctionalsProps {
  initialCopy: {
    head: CopyStoreType['copy']['head'];
    global: CopyStoreType['copy']['global'];
    app: CopyStoreType['copy']['app'];
  };
  children: React.ReactNode | React.ReactNode[];
  isBypassed?: boolean;
  router: Router;
}

const NonFunctionals: React.FunctionComponent<NonFunctionalsProps> = ({
  initialCopy,
  children,
  isBypassed = false,
  router,
}) => {
  const [isMounted, setMounted] = useState(false);
  const [isRendered, setRendered] = useState(false);
  const [showUnsupportedBrowser, setShowUnsupportedBrowser] = useState(false);
  const [isSubStandardBrowserAccepted, setSubstandardBrowserAccepted] =
    useState(false);
  const [hasBanner, setHasBanner] = useState(false);

  const hasMatchingRoute = useMemo(() => {
    const isStaticRouteMatch = Object.values(ROUTES).includes(
      router.route as typeof ROUTES[keyof typeof ROUTES]
    );
    const isDynamicRouteMatch = Object.values(ROUTES).some(routePattern => {
      const regexPattern = routePattern.replace(/\[.*?\]/g, '.*?'); // Replace dynamic segments with a regex wildcard
      const regex = new RegExp(`^${regexPattern}$`);
      return regex.test(router.route);
    });

    return (
      (isStaticRouteMatch || isDynamicRouteMatch) &&
      router.route !== ROUTES.NOT_FOUND
    );
  }, [router.route]);

  const windowSize = useWindowSize(true);
  const { setCopy } = useCopyStore();
  const { isWindowTooSmall, setWindowTooSmall } = useGlobalStore();

  const errorComponent = useMemo(() => {
    if (
      isMounted &&
      (!ALLOWED_BROWSERS.tablet.length || !ALLOWED_BROWSERS.mobile.length) &&
      !isDesktop()
    ) {
      return <DesktopOnly />;
    } else if (showUnsupportedBrowser && !isSubStandardBrowserAccepted) {
      return <UnsupportedBrowser setAccept={setSubstandardBrowserAccepted} />;
    } else if (!hasMatchingRoute) {
      return <NotFound />;
    }

    return null;
  }, [
    isMounted,
    hasMatchingRoute,
    isSubStandardBrowserAccepted,
    showUnsupportedBrowser,
  ]);

  useEffect(() => {
    setMounted(true);

    if (process.env.IS_DEBUG && router.asPath.match(/^\/make-frontend-error/)) {
      throw new Error('Testing Sentry');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isMounted) {
      setRendered(true);

      const { isSupported, needsUpgrade } = isSupportedBrowser();

      if (!isSupported) {
        setShowUnsupportedBrowser(true);
      } else if (needsUpgrade) {
        setHasBanner(true);
      }
    }
  }, [isMounted]);

  useEffect(() => {
    const zoomLevel = process.env.ALLOW_USER_ZOOM
      ? windowSize.devicePixelRatio
      : 1;

    const windowSizeNew =
      isDesktop() &&
      (windowSize.width < desktopMinWidth / zoomLevel ||
        windowSize.height < desktopMinHeight / zoomLevel);

    if (windowSizeNew !== isWindowTooSmall) setWindowTooSmall(windowSizeNew);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize]);

  useEffect(() => {
    if (isStorybook() || !initialCopy) return;
    const { head, global } = initialCopy;

    // If this is an error page, set the shared copy
    if (errorComponent) {
      setCopy({ global });
    }

    // If this is the 404 page, update the head copy
    if (!hasMatchingRoute) {
      setCopy({ head });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorComponent, hasMatchingRoute]);

  const handleBannerContentClick = () => {
    setShowUnsupportedBrowser(true);
  };

  if (isBypassed) return <>{children}</>;

  return (
    <>
      <AnimatePresence mode="wait" initial={false}>
        {errorComponent || children}
      </AnimatePresence>
      {!isRendered && (
        <noscript>
          <JavaScriptDisabled
            sharedCopy={{ global: initialCopy?.global, app: initialCopy?.app }}
          />
        </noscript>
      )}
      {isRendered && (
        <UnsupportedBanner
          onContentClick={handleBannerContentClick}
          isVisible={hasBanner && !isSubStandardBrowserAccepted}
        />
      )}

      {isRendered && (
        <WindowTooSmall isVisible={isWindowTooSmall} isEnabled={false} />
      )}
    </>
  );
};

export default React.memo(NonFunctionals);
