import { type VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';

import {
  fontSizeMap,
  sizeMap,
  spaceMap,
  userInitials,
} from 'components/WsAvatar';

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';

const avatarStackVariants = cva('flex', {
  variants: {
    orientation: {
      vertical: 'flex-row',
      horizontal: 'flex-col',
    },
  },
  defaultVariants: {
    orientation: 'vertical',
  },
});

export interface AvatarStackProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof avatarStackVariants> {
  avatars: { name: string; photoURL: string }[];
  maxAvatarsAmount?: number;
  size?: keyof typeof sizeMap;
}

const AvatarStack = ({
  className,
  orientation,
  avatars,
  maxAvatarsAmount = 3,
  size = 'base',
  ...props
}: AvatarStackProps) => {
  const shownAvatars = avatars.slice(0, maxAvatarsAmount);
  const hiddenAvatars = avatars.slice(maxAvatarsAmount);

  return (
    <div
      className={cn(
        avatarStackVariants({ orientation }),
        className,
        spaceMap[size],
        orientation === 'horizontal' ? '-space-x-0' : '-space-y-0'
      )}
      {...props}
    >
      {shownAvatars.map(({ name, photoURL }, index) => (
        <TooltipProvider delayDuration={300} key={`${photoURL}-${index + 1}`}>
          <Tooltip>
            <TooltipTrigger asChild>
              <Avatar
                className={cn(
                  avatarStackVariants(),
                  sizeMap[size],
                  'hover:z-10'
                )}
              >
                <AvatarImage src={photoURL} />
                <AvatarFallback
                  className={cn(fontSizeMap[size], 'select-none')}
                >
                  {userInitials(name)}
                </AvatarFallback>
              </Avatar>
            </TooltipTrigger>
            <TooltipContent>
              <p>{name}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ))}

      {hiddenAvatars.length ? (
        <TooltipProvider delayDuration={300}>
          <Tooltip>
            <TooltipTrigger asChild>
              <Avatar className={cn(sizeMap[size])}>
                <AvatarFallback
                  className={cn(fontSizeMap[size], 'select-none')}
                >
                  +{avatars.length - shownAvatars.length}
                </AvatarFallback>
              </Avatar>
            </TooltipTrigger>
            <TooltipContent>
              {hiddenAvatars.map(({ name }, index) => (
                <p key={`${name}-${index + 1}`}>{name}</p>
              ))}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ) : null}
    </div>
  );
};

export { AvatarStack, avatarStackVariants };
